import { fetchApi, ApiClient, getWebHost } from '@/utils/fetch'
import {
  Venue,
  GetPreferences,
  VenueUpdateBody,
  OrderModeValue,
  CropBox,
  VenueResponse,
  BillingSubscription,
} from 'shared/types/api'
import { GetStaticProps } from 'next'
import { fromEventPattern, Observable } from 'rxjs'
import { useOrderManager } from './order'
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useAdmin } from '@/components/admin'
import { useRouter } from 'next/router'
import { isBrowser, isDevBrowser, localStorage } from '@/utils/env'
import { customDomains } from 'shared/custom-domains'
import { useConstant } from '@/utils/use-constant'
import { useAvailablePreferences } from './preferences'
import Head from 'next/head'
import parse from 'html-react-parser'
import { Tracker } from './tracking'
import fromEntries from 'object.fromentries'
import { uploadPresignedFile } from './product'
import { daysOfWeek, parseTime, createIntervalFromTime } from '@/utils/date'
import { returnOrThrow } from 'shared/utils/types'
import getISODay from 'date-fns/getISODay'
import isWithinInterval from 'date-fns/isWithinInterval'
import setDay from 'date-fns/setDay'
import addWeeks from 'date-fns/addWeeks'
import setMinutes from 'date-fns/setMinutes'
import setHours from 'date-fns/setHours'
import addDays from 'date-fns/addDays'
import type { Locale } from 'date-fns'
import { ResourceLanguage } from 'i18next'
import { useAppContext } from '@/pages/_app'
import { entries, keys, unique } from 'shared/utils/array'
import ruLocale from 'date-fns/locale/ru'
import enLocale from 'date-fns/locale/en-GB'
import esLocale from 'date-fns/locale/es'
import trLocale from 'date-fns/locale/tr'
import deLocale from 'date-fns/locale/de'
import en from '@root/public/lang/en.json'
import { useGdprConsent } from './gdpr-consent'
import { delay, filter, mapTo, startWith, take } from 'rxjs/operators'
import { makeYandexMetrika, YA_METRIKA_ID } from './yandex-metrika'
import { GdprConsent } from '@/components/gdpr-consent'
import {
  useClientValue,
  useDefaultObservable,
  useStateFromUiEvents,
  useSubject,
  useSubscription,
} from '@/utils/event'
import { makeFreshchat } from './freshchat'
import { FreshChatStyles } from '@/components/freshchat'
import dynamic from 'next/dynamic'
import { makeJivosite } from './jivosite'
import { NotFoundError } from '@/utils/fetch-error'
import { rotate } from 'shared/utils/array'
import { venueGetOrderingTypePaymentMethods } from 'shared/venue'

const CropperOverlay = dynamic(import('@/components/cropper'))

const defaultLocale = enLocale
const locales: Record<string, Locale> = {
  ru: ruLocale,
  en: enLocale,
  es: esLocale,
  tr: trLocale,
  de: deLocale,
}

const API_FLAGS: string[] = []

export type TVenueContextVenueData = {
  availablePreferences: GetPreferences.AvailablePreferences
  venueName: string
  venue: Venue
  billing: { subscription: BillingSubscription }
  locale: Locale
  refreshVenue: () => void
  venueUpdated$: Observable<void>
  menuRoute: {
    path: string
  }
}

export type TVenueContext = TVenueContextVenueData & {
  orderManager: ReturnType<typeof useOrderManager>
  admin: ReturnType<typeof useAdmin>
  orderTypeHasPaymentMethods: boolean
}

export const VenueContext = createContext<TVenueContext | null>(null)

export function useVenueContext() {
  return useContext(VenueContext)
}

export function VenueContextController(
  props: PropsWithChildren<{
    tracker: Tracker
    venueResponse?: VenueResponse
    availablePreferences?: GetPreferences.AvailablePreferences
    translation?: ResourceLanguage
  }>,
) {
  const { i18n } = useAppContext()
  const router = useRouter()
  const venueResponseFromProps = props.venueResponse
  const venueFromProps = venueResponseFromProps?.venue
  const venueNameFromQuery = router.query.venueName as string

  const domain = isBrowser ? location.host : 'menu.fdn.gg'
  const venueNameFromCustomDomain = customDomains[domain]

  const venueName = venueFromProps?.name ?? venueNameFromCustomDomain ?? venueNameFromQuery

  const defaultMenuRoute = `/venue/${venueName}`
  const menuRoute = {
    path: useClientValue(defaultMenuRoute, venueNameFromCustomDomain ? '/' : defaultMenuRoute)
      .value,
  }

  const venueUpdated$ = useSubject<void>()

  const [runtimeVenueVersion, setRuntimeVenueVersion] = useState(() => {
    return Math.random()
  })

  const venueResponseOnClient = useVenueResponse(venueName, {
    venue: venueFromProps,
    dummy: !venueName,
    runtimeVersion: runtimeVenueVersion,
    onVenueResponseFromApi: () => {
      venueUpdated$.next()
    },
  })
  const venueOnClient =
    venueResponseOnClient instanceof Error ? venueResponseOnClient : venueResponseOnClient?.venue

  const [isTranslationLoaded, setTranslationLoaded] = useState(!!props.translation)

  const addTranslation = (language: string, translation: ResourceLanguage) => {
    for (const [namespace, dictionary] of entries(translation)) {
      i18n.addResourceBundle(language, namespace.toString(), dictionary)
    }

    const venue = venueOnClient ?? venueFromProps
    if (!venue || venue instanceof Error) {
      return
    }

    for (const [language, languageConfig] of entries(venue.features.languageConfig ?? {})) {
      for (const [namespace, dictionary] of entries(languageConfig.overrideTranslation ?? {})) {
        i18n.addResourceBundle(language.toString(), namespace.toString(), dictionary)
      }
    }
  }

  useConstant(() => {
    if (venueFromProps && props.translation) {
      addTranslation(venueFromProps.language, props.translation)
      i18n.changeLanguage(venueFromProps.language)
    }
  })

  const chosenLanguage$ = useStateFromUiEvents<null | string>({
    id: `chosenLanguage/${venueName}`,
    startingValue: null,
    storage: localStorage,
    process: (source, scan) => {
      return source.pipe(
        scan((language, event) => {
          switch (event.type) {
            case 'LanguageModalMenuChooseLanguage':
              return event.chosenLanguage
          }
          return language
        }),
      )
    },
  })

  useSubscription(chosenLanguage$, async (language) => {
    if (!language) {
      return
    }
    if (!i18n.languages.includes(language)) {
      const translation: ResourceLanguage = await fetch(
        `${getWebHost()}/lang/${language}.json`,
      ).then((res) => {
        return res.json()
      })

      addTranslation(language, translation)
    }

    i18n.changeLanguage(language)
  })

  const chosenLanguage = useDefaultObservable(chosenLanguage$)

  useEffect(() => {
    ;(async () => {
      const venue = venueOnClient ?? venueFromProps

      if (!venue || venue instanceof Error) {
        return
      }

      const supportedLanguages = unique([
        venue.language,
        ...keys(venue.features.languageConfig ?? {}),
      ])

      const browserLanguage = navigator.language
        ? navigator.language.toLowerCase().split('-')[0]!
        : undefined
      const validatedBrowserLanguage =
        browserLanguage && supportedLanguages.includes(browserLanguage) ? browserLanguage : null

      const validatedChosenLanguage =
        chosenLanguage && supportedLanguages.includes(chosenLanguage) ? chosenLanguage : null

      const venueLanguage = validatedChosenLanguage ?? validatedBrowserLanguage ?? venue.language

      if (venueLanguage === i18n.language) {
        return setTranslationLoaded(true)
      }

      const translation: ResourceLanguage = await (() => {
        if (props.translation && !validatedBrowserLanguage) {
          return props.translation
        }

        return fetch(`${getWebHost()}/lang/${venueLanguage}.json`).then((res) => {
          return res.json()
        })
      })()

      addTranslation(venueLanguage, translation)
      await i18n.changeLanguage(venueLanguage)
      setTranslationLoaded(true)
    })()
  }, [venueOnClient])

  const venue = (() => {
    if (venueOnClient instanceof Error) {
      console.error(`error getting venue: ${venueOnClient.stack ?? venueOnClient.toString()}`)
      return venueFromProps
    }

    return venueOnClient ?? venueFromProps
  })()

  const availablePreferencesOnClient = useAvailablePreferences()

  const billingOnClient =
    venueResponseOnClient && !(venueResponseOnClient instanceof Error)
      ? venueResponseOnClient.billing
      : undefined

  const billing = billingOnClient ?? venueResponseFromProps?.billing

  // when the page is not associated with a venue in any way
  if (!venueName) {
    // the page is associated with a venue, but the query params were not yet provided due to hydration
    // https://nextjs.org/docs/routing/dynamic-routes#caveats
    // > Pages that are statically optimized by Automatic Static Optimization will be hydrated without their route parameters provided, i.e query will be an empty object ({}).
    if (router.route.includes('[venueName]')) {
      return null
    }

    return <>{props.children}</>
  }

  // the page is associated with a venue, but it's client-rendered
  // we wait for the venue to load asynchronously
  if (!venue || !billing) {
    return null
  }

  const locale = locales[venue.language] ?? defaultLocale

  if (!isTranslationLoaded) {
    return null
  }

  const availablePreferences = (() => {
    if (availablePreferencesOnClient instanceof Error) {
      console.error(
        `error getting available preferences: ${
          availablePreferencesOnClient.stack ?? availablePreferencesOnClient.toString()
        }`,
      )
      return props.availablePreferences
    }

    return availablePreferencesOnClient ?? props.availablePreferences
  })()

  // waiting for availablePreferences to load
  if (!availablePreferences) {
    return null
  }

  return (
    <VenueContextProvider
      venueData={{
        venueName,
        venue,
        billing,
        locale,
        availablePreferences,
        venueUpdated$,
        menuRoute,
        refreshVenue() {
          return setRuntimeVenueVersion(Math.random())
        },
      }}
    >
      <VenueRelatedPage>{props.children}</VenueRelatedPage>
    </VenueContextProvider>
  )
}

export function VenueContextProvider(
  props: PropsWithChildren<{ venueData: TVenueContextVenueData }>,
) {
  const { venue } = props.venueData
  const admin = useAdmin({ venueName: venue.name })
  const orderManager = useOrderManager(venue)

  const currentOrderMode = orderManager.orderMode

  const orderTypeHasPaymentMethods =
    Object.keys(venueGetOrderingTypePaymentMethods(venue.features, currentOrderMode.value)).length >
    0

  const venueContext: TVenueContext = {
    ...props.venueData,
    orderManager,
    admin,
    orderTypeHasPaymentMethods,
  }

  return <VenueContext.Provider value={venueContext}>{props.children}</VenueContext.Provider>
}

export function VenueRelatedPage(props: PropsWithChildren<unknown>) {
  const router = useRouter()
  const { tracker } = useAppContext()
  const { venue, admin } = useVenueContext()!
  const { userAgreedToCookies$ } = useGdprConsent()

  useEffect(() => {
    tracker.setContext({
      venueName: venue.name,
    })
  }, [])

  const routeChanges$ = useConstant(() => {
    return fromEventPattern(
      (handler) => {
        return router.events.on('routeChangeComplete', handler)
      },
      (handler) => {
        return router.events.off('routeChangeComplete', handler)
      },
    ).pipe(mapTo(undefined))
  })

  useSubscription(
    userAgreedToCookies$.pipe(
      startWith(userAgreedToCookies$.defaultValue),
      filter((v) => {
        return v === true
      }),
      delay(3000),
    ),
    () => {
      if (isDevBrowser || venue.features.disableAnalytics) {
        return
      }
      // const googleAnalytics = makeGoogleAnalytics(
      //   routeChanges$.pipe(
      //     map(() => {
      //       const url = new URL(location.href)
      //       const orderModeValue = orderManager.orderMode.value
      //
      //       if (orderModeValue) {
      //         url.searchParams.set('om', orderModeValue)
      //       }
      //
      //       return url
      //     }),
      //   ),
      //   tracker.events$,
      // )
      //
      // const companyTrackingId = serviceBrands[venue.serviceBrand].googleAnalytics.trackingId
      // googleAnalytics.initScript(companyTrackingId)
      // googleAnalytics.registerTrackingId(companyTrackingId, {
      //   transport_type: 'beacon',
      // })
      //
      // const facebookPixel = makeFacebookPixel(routeChanges$)
      // facebookPixel.initScript()
      // facebookPixel.registerTrackingId(FACEBOOK_PIXEL_ID)
      // for (const id of venue.features.facebookPixelIds ?? []) {
      //   facebookPixel.registerTrackingId(id)
      // }

      const yandexMetrika = makeYandexMetrika(routeChanges$)
      yandexMetrika.initScript()
      yandexMetrika.registerTrackingId(YA_METRIKA_ID, {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      })
      for (const id of venue.features.yaMetrikaIds ?? []) {
        yandexMetrika.registerTrackingId(id)
      }
    },
  )

  useSubscription(
    admin.authorizationStatus$.pipe(
      filter((authStatus) => {
        return authStatus.state.type === 'ok'
      }),
      take(1),
    ),
    (authorizationStatus) => {
      if (authorizationStatus.state.type !== 'ok') {
        return
      }

      if (venue.features.freshChatToken) {
        const freshChat = makeFreshchat({
          token: venue.features.freshChatToken,
          authorizationStatus,
          venue,
        })
        freshChat.initScript()
      }

      if (venue.features.jivositeToken) {
        const jivosite = makeJivosite({
          token: venue.features.jivositeToken,
          authorizationStatus,
          venue,
        })
        jivosite.initScript()
      }
    },
  )

  const canonical = (() => {
    const customDomain = entries(customDomains).find(([, venueName]) => {
      return venueName === venue.name
    })?.[0]

    if (!customDomain) {
      return null
    }

    const path = ['/venue/[venueName]/customDomain', '/venue/[venueName]'].includes(router.route)
      ? ''
      : router.asPath

    return `https://${customDomain}${path}`
  })()

  return (
    <>
      {canonical && (
        <Head>
          <link rel="canonical" href={canonical} />
        </Head>
      )}
      {venue.features.headHtml && <Head>{parse(venue.features.headHtml)}</Head>}
      {venue.features.freshChatToken && <FreshChatStyles />}
      <GdprConsent />
      <CropperOverlay />
      {props.children}
    </>
  )
}

function useVenueResponse(
  venueName: string,
  params: {
    venue?: Venue
    dummy?: boolean
    runtimeVersion?: number
    onVenueResponseFromApi?: (val: VenueResponse) => void
  } = {},
) {
  const query = new URLSearchParams(
    fromEntries(
      Object.entries({
        flags: API_FLAGS.join(',') || undefined,
        __ERROR__: localStorage.getItem('forceApiError') ? '1' : undefined,
      })
        .filter(([, value]) => {
          return value !== undefined
        })
        .map(([key, value]) => {
          return [key, value!]
        }),
    ),
  ).toString()

  const { apiClient } = useAppContext()
  if (params.venue) {
    apiClient.setVenue(params.venue)
  }

  const [resultFromApi, setResultFromApi] = useState<VenueResponse | Error | null>(null)

  useEffect(() => {
    if (params.dummy) {
      return
    }
    apiClient
      .fetch(`/venue/${venueName}${query ? `?${query}` : ''}`)
      .then(async (res) => {
        const venueResponseOrError =
          res instanceof Error ? res : ((await res.json()) as VenueResponse)
        if (!(venueResponseOrError instanceof Error) && params.onVenueResponseFromApi) {
          params.onVenueResponseFromApi(venueResponseOrError)
        }

        return setResultFromApi(venueResponseOrError)
      })
      .catch((err) => {
        console.error(`couldn't fetch the venue on the client`, err)
      })
  }, [params.runtimeVersion, params.dummy])

  return resultFromApi
}

export function updateVenue(apiClient: ApiClient, venueName: string, newValues: VenueUpdateBody) {
  return apiClient.fetch(`/admin/venue/${venueName}`, {
    method: 'POST',
    body: JSON.stringify(newValues),
  })
}

export async function uploadVenueImage(
  venueName: string,
  file: File,
  cropBox: CropBox,
  apiClient: ApiClient,
) {
  const baseName = `${venueName}-header`
  const imageUrl = await uploadPresignedFile(venueName, baseName, file, apiClient)
  if (!imageUrl) {
    return
  }
  if (imageUrl instanceof Error) {
    return imageUrl
  }

  const body: VenueUpdateBody = {
    headerPicture: {
      url: imageUrl,
      cropBox,
    },
  }

  return updateVenue(apiClient, venueName, body)
}

export type VenueScheduleStatus =
  | {
      isOpen: true
    }
  | {
      isOpen: false
      opensAt: Date | 'never'
    }
export function getVenueScheduleStatus(
  venue: Venue,
  orderMode: OrderModeValue,
): VenueScheduleStatus {
  const workSchedule = venue.features.workSchedule ?? []

  const workScheduleForCurrentMode = workSchedule.find((ws) => {
    return ws.orderModes.includes(orderMode)
  })

  if (!workScheduleForCurrentMode) {
    return { isOpen: true }
  }

  const now = new Date()
  const dayOfWeekTodayNumber = getISODay(now) - 1
  const dayOfWeekYesterdayNumber = dayOfWeekTodayNumber === 0 ? 6 : dayOfWeekTodayNumber - 1
  const todayTimeTable = workScheduleForCurrentMode.timeTable[daysOfWeek[dayOfWeekTodayNumber]!]
  const yesterdayTimeTable =
    workScheduleForCurrentMode.timeTable[daysOfWeek[dayOfWeekYesterdayNumber]!]

  // shouldn't be the case, but if we accidentally forget to set the time table for some particular day,
  // then consider the venue to be open 24h
  if (!todayTimeTable) {
    return { isOpen: true }
  }

  // positive means browser is ahead
  // negative means browser is behind
  const timeZoneDifference = (() => {
    // assume the browser is in the same timezone with the venue
    if (!workScheduleForCurrentMode.timezoneIana) {
      return 0
    }

    try {
      const nowWithTz = new Date(
        now.toLocaleString('en-US', {
          timeZone: workScheduleForCurrentMode.timezoneIana,
        }),
      )
      const timeZoneDifferenceMs = now.getTime() - nowWithTz.getTime()
      const timeZoneDifferenceHours = Math.round(timeZoneDifferenceMs / 1000 / 60 / 60)
      return -timeZoneDifferenceHours
    } catch (e) {
      console.error(e)
      return 0
    }
  })()

  const parseTimeAdjustingTimezone = (input: string) => {
    const parsedTime = returnOrThrow(parseTime(input))
    return {
      hours: parsedTime.hours - timeZoneDifference,
      minutes: parsedTime.minutes,
    }
  }

  const zeroInterval = { start: new Date(0), end: new Date(0) }

  const timeToInterval = (start: string, end: string, date: Date) => {
    return createIntervalFromTime(
      parseTimeAdjustingTimezone(start),
      parseTimeAdjustingTimezone(end),
      date,
    )
  }

  const todayInterval =
    todayTimeTable === 'closed'
      ? zeroInterval
      : timeToInterval(todayTimeTable.opensAt, todayTimeTable.closesAt, now)

  const yesterdayInterval =
    !yesterdayTimeTable || yesterdayTimeTable === 'closed'
      ? zeroInterval
      : timeToInterval(yesterdayTimeTable.opensAt, yesterdayTimeTable.closesAt, addDays(now, -1))

  const isWithinYesterday = isWithinInterval(now, yesterdayInterval)
  const isWithinToday = isWithinInterval(now, todayInterval)

  const nextOpensAt = (() => {
    const scheduleArray = daysOfWeek.map((dayOfWeek) => {
      return {
        dayOfWeek,
        schedule: workScheduleForCurrentMode.timeTable[dayOfWeek],
      }
    })

    const thisWeek = scheduleArray.find(({ schedule }, index) => {
      const includeToday = todayInterval.start > now
      return (
        (includeToday ? index >= dayOfWeekTodayNumber : index > dayOfWeekTodayNumber) &&
        schedule &&
        schedule !== 'closed'
      )
    })

    const nextWeek = scheduleArray.find(({ schedule }) => {
      return schedule && schedule !== 'closed'
    })

    const setDayWeekFormat = rotate(daysOfWeek, -1)

    if (thisWeek) {
      const { dayOfWeek, schedule } = thisWeek
      if (schedule !== 'closed') {
        const { hours, minutes } = returnOrThrow(parseTime(schedule!.opensAt))
        const opensAtDate = setMinutes(setHours(now, hours), minutes)
        return setDay(opensAtDate, setDayWeekFormat.indexOf(dayOfWeek), { weekStartsOn: 1 })
      }
    }

    if (nextWeek) {
      const { dayOfWeek, schedule } = nextWeek
      if (schedule !== 'closed') {
        const { hours, minutes } = returnOrThrow(parseTime(schedule!.opensAt))
        const opensAtDate = addWeeks(setMinutes(setHours(now, hours), minutes), 1)
        return setDay(opensAtDate, setDayWeekFormat.indexOf(dayOfWeek), { weekStartsOn: 1 })
      }
    }

    return 'never'
  })()

  const isOpen = isWithinToday || isWithinYesterday

  return isOpen
    ? {
        isOpen: true,
      }
    : {
        isOpen: false,
        opensAt: nextOpensAt,
      }
}

export type VenueBasedPageProps = {
  venue?: Venue
  availablePreferences?: GetPreferences.AvailablePreferences
  translation: ResourceLanguage
}

export const getVenueStaticProps: GetStaticProps<VenueBasedPageProps> = async (ctx) => {
  const venueName = ctx.params?.venueName as string
  const venueUrl = `/venue/${venueName}?flags=${API_FLAGS.join(',')}`

  const fetchBody = async (path: string) => {
    const response = await fetchApi(path)
    if (response instanceof Error) {
      return {
        error: response,
      }
    }
    const body = await response.json()
    return {
      body,
    }
  }
  const venueResponse = await fetchBody(venueUrl)

  if (venueResponse.error) {
    if (venueResponse.error instanceof NotFoundError) {
      return {
        notFound: true,
      }
    }

    throw venueResponse.error
  }

  const venueResponseBody: VenueResponse = venueResponse.body
  const { venue } = venueResponseBody

  // "en" is the most common language and supposed to be distributed directly to the client
  const translation =
    venue.language !== 'en'
      ? ((await fetch(`${getWebHost()}/lang/${venue.language}.json`).then((res) => {
          return res.json()
        })) as ResourceLanguage)
      : en

  const preferencesUrl = '/preferences'
  const preferencesResponse = await fetchBody(preferencesUrl)

  if (preferencesResponse.error) {
    throw preferencesResponse.error
  }

  const availablePreferences = preferencesResponse.body

  return {
    revalidate: 5,
    props: {
      venueResponse: venueResponseBody,
      availablePreferences,
      translation,
    },
  }
}

export async function getVenueStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  }
}
