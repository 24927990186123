import { useAppContext } from '@/pages/_app'
import { uiEvents, useDefaultObservable, useStateFromUiEvents } from '@/utils/event'
import { createContext, PropsWithChildren } from 'react'

declare global {
  interface AppUIEventMap {
    CollapseToggle: {
      type: 'CollapseToggle'
      id: string
    }
    CollapseExpand: {
      type: 'CollapseExpand'
      id: string
    }
  }
}

export type TCollapseContext = {
  id: string
}
export const CollapseContext = createContext<TCollapseContext | null>(null)

export type CollapseProps = PropsWithChildren<{
  id: string
  title: string
}>

export function Collapse(props: CollapseProps) {
  const { t } = useAppContext()

  const collapseVisibility$ = useStateFromUiEvents({
    id: `collapse/${props.id}`,
    startingValue: false,
    storage: localStorage,
    process: (source, scan) => {
      return source.pipe(
        scan((visibility, event) => {
          switch (event.type) {
            case 'CollapseExpand':
              if (event.id !== props.id) {
                return visibility
              }
              return true
            case 'CollapseToggle':
              if (event.id !== props.id) {
                return visibility
              }
              return !visibility
            default:
              return visibility
          }
        }),
      )
    },
  })

  const collapseContentIsVisible = useDefaultObservable(collapseVisibility$)

  return (
    <>
      <div
        className="flex h-16 p-5 border-t border-opaqueLightest bg-backOpaque dark:bg-backOpaqueDark text-lg items-center justify-between cursor-pointer"
        onClick={() => {
          uiEvents.next({
            type: 'CollapseToggle',
            id: props.id,
          })
        }}
      >
        <div>{props.title}</div>
        <button
          type="button"
          className="flex justify-center items-center py-2 px-5 text-xs bg-back dark:bg-backDark border border-opaqueLightest rounded-md focus:outline-none"
        >
          <div
            style={{
              transform: collapseContentIsVisible ? 'rotate(180deg)' : undefined,
            }}
          >
            <svg
              width="10"
              fill="none"
              height="7"
              viewBox="0 0 10 7"
              className="stroke-primary dark:stroke-primaryDark"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 0.909973L5 5.90997L1 0.909973"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="ml-1">{collapseContentIsVisible ? t('collapse') : t('expand')}</div>
        </button>
      </div>

      <div className={`px-5 ${collapseContentIsVisible ? 'visible' : 'hidden'}`}>
        <CollapseContext.Provider value={{ id: props.id }}>
          {props.children}
        </CollapseContext.Provider>
      </div>
    </>
  )
}
