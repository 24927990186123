import { STORAGE_CACHE_FRAGMENT } from './fetch'
import { getDictStorage } from './persistant-state-hooks'

export function purgeStorageCache(storage: Storage) {
  Object.keys(storage)
    .filter((key) => {
      return key.includes(STORAGE_CACHE_FRAGMENT)
    })
    .forEach((key) => {
      storage.removeItem(key)
    })
}

export function checkSchemaVersionStatus(
  storageKey: string,
  schemaVersion: number | undefined,
  storage: Storage | undefined,
) {
  const schemaVersionStorage = getDictStorage<number>('schemaVersion', storage)
  const localSchemaVersion = schemaVersionStorage.get()[storageKey]

  if (localSchemaVersion !== schemaVersion) {
    schemaVersionStorage.update({
      ...schemaVersionStorage.get(),
      [storageKey]: schemaVersion,
    })

    return 'outdated'
  }

  return 'upToDate'
}
