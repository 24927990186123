import { getStorage } from '@/utils/persistant-state-hooks'
import { sessionStorage, localStorage, isBrowser, isDevBrowser } from '@/utils/env'
import { nanoid } from 'nanoid'
import { Subject, merge, interval, fromEvent, Observable, NEVER } from 'rxjs'
import { bufferWhen, filter, map, throttleTime } from 'rxjs/operators'
import { useAppContext } from '@/pages/_app'
import { purgeStorageCache as purgeStorageCaches } from '../utils/storage'

export type Event = {
  eventName: string
  browserUuid: string
  sessionUuid: string
  visitUuid: string
  browserTime: Date
  url: string
}

export type Tracker = {
  setContext(context: Record<string, unknown>): void
  track(eventName: string, payload?: Record<string, unknown>): void
  trackError(error?: Error, payload?: Record<string, unknown>): void
  events$: Observable<Event>
}

declare global {
  interface Window {
    visitUuid?: string
  }
}

export function trackErrors(tracker: Tracker) {
  if (!isBrowser) {
    return
  }

  window.addEventListener('unhandledrejection', (errorEvent) => {
    if (!isDevBrowser) {
      purgeStorageCaches(localStorage)
    }
    const { reason } = errorEvent

    if (reason instanceof Error) {
      const error = reason
      tracker.trackError(error, { isRejectedPromise: true })
    }
  })

  window.addEventListener('error', (errorEvent) => {
    if (!isDevBrowser) {
      purgeStorageCaches(localStorage)
    }
    const { message, filename, lineno, colno, error } = errorEvent
    tracker.trackError(error || new Error(message), { message, filename, lineno, colno })
  })
}

export function useTracker() {
  return useAppContext().tracker
}

export function registerTracker(): Tracker {
  if (!isBrowser) {
    return {
      setContext() {
        return
      },
      track() {
        console.log()
      },
      trackError() {
        console.log()
      },
      events$: NEVER,
    }
  }

  const browserUuidStorage = getStorage<string>('browserUuid', localStorage)
  const sessionUuidStorage = getStorage<string>('sessionUuid', sessionStorage)

  const browserUuid = browserUuidStorage.get() || nanoid()
  const sessionUuid = sessionUuidStorage.get() || nanoid()
  const visitUuid = window.visitUuid || nanoid()

  browserUuidStorage.update(browserUuid)
  sessionUuidStorage.update(sessionUuid)

  // eslint-disable-next-line immutable/no-mutation
  window.visitUuid = visitUuid

  const makeEvent = (eventName: string, payload?: Record<string, unknown>): Event => {
    return {
      eventName,
      browserUuid,
      sessionUuid,
      visitUuid,
      browserTime: new Date(),
      url: location.href,
      ...payload,
    }
  }

  const redirectUuid = getRedirectUuidFromQueryParams()
  if (redirectUuid) {
    send([makeEvent('navigation/after-redirect', { redirectUuid })])
  }

  const eventBuffer = new Subject<Event>()
  const beforeUnload$ = fromEvent(window, 'beforeunload')
  const pagehide$ = fromEvent(window, 'pagehide')
  const visibilitychange$ = fromEvent(document, 'visibilitychange').pipe(
    filter(() => {
      return document.visibilityState === 'hidden'
    }),
  )
  const userIsGone$ = merge(beforeUnload$, pagehide$, visibilitychange$).pipe(throttleTime(1000))
  const bufferFlush$ = merge(interval(100), userIsGone$)

  // explicit state
  // eslint-disable-next-line immutable/no-let
  let context: Record<string, unknown> = {}

  eventBuffer
    .pipe(
      bufferWhen(() => {
        return bufferFlush$
      }),
      filter((events) => {
        return events.length > 0
      }),
      map((events) => {
        return events.map((event) => {
          return {
            ...context,
            ...event,
          }
        })
      }),
    )
    .subscribe((events) => {
      send(events)
    })

  userIsGone$.subscribe(() => {
    send([makeEvent('navigation/leave')])
  })

  const tracker: Tracker = {
    setContext(newContext) {
      context = newContext
    },
    track(eventName, payload = {}) {
      eventBuffer.next(makeEvent(eventName, payload))
    },
    trackError(error, payload = {}) {
      eventBuffer.next(
        makeEvent('error', {
          error: `Message: ${error?.message || 'none'}\nStack: ${error?.stack || 'none'}`,
          ...payload,
        }),
      )
    },
    events$: eventBuffer.asObservable(),
  }

  return tracker
}

function getRedirectUuidFromQueryParams() {
  const url = new URL(location.href)
  const redirectUuid = url.searchParams.get('r')

  if (!redirectUuid) {
    return undefined
  }

  url.searchParams.delete('r')
  history.replaceState(null, '', url.toString())

  return redirectUuid as string
}

function send(events: Event[]) {
  if (localStorage.getItem('DEV_TRACK')) {
    console.log('track', ...events)
  }

  if (isDevBrowser && !localStorage.getItem('DEV_TRACK_SRV')) {
    return false
  }

  const url = `${location.origin}/proxy-api/track`

  // turn undefined into null so it goes into tracking
  const body = JSON.stringify(events, (_, value) => {
    return typeof value === 'undefined' ? null : value
  })

  if (navigator.sendBeacon) {
    return navigator.sendBeacon(url, body)
  }

  return fetch(url, { body })
}
