import { LayoutApp } from '@/components/layout'
import { useAppContext } from '@/pages/_app'
import { makeFreshchat } from '@/services/freshchat'
import { makeJivosite } from '@/services/jivosite'
import { useVenueContext } from '@/services/venue'
import { uiEvents } from '@/utils/event'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import Link from '@mui/material/Link'

export function AdminLoginManual() {
  const { t } = useAppContext()
  const { venue, admin } = useVenueContext()!
  const { authorizationStatus } = admin

  useEffect(() => {
    uiEvents.next({ type: 'AppLoaded' })

    if (venue.features.freshChatToken) {
      const freshChat = makeFreshchat({
        token: venue.features.freshChatToken,
        authorizationStatus,
        venue,
      })
      freshChat.initScript()
    }

    if (venue.features.jivositeToken) {
      const jivosite = makeJivosite({
        token: venue.features.jivositeToken,
        authorizationStatus,
        venue,
      })
      jivosite.initScript()
    }
  }, [])

  return (
    <LayoutApp>
      <div className="p-5" style={{ backgroundColor: '#ffeec1' }}>
        <h1 className="text-2xl mb-4">{t('adminLogin:adminArea')}</h1>
        <div>
          <Trans
            t={t}
            i18nKey="adminLogin:manualForEmail"
            components={{
              p: <p className="my-4" />,
              aHowToPay: (
                <Link
                  href="https://foodeon.com/ru/blog/tpost/zss8olhnd1-kak-oplatit-servis-foodeon"
                  target="_blank"
                />
              ),
            }}
          />
        </div>

        <a
          className="mt-4 flex items-center h-12 text-lg justify-center rounded-lg cursor-pointer"
          style={{
            backgroundColor: '#ffbc33',
          }}
          onClick={() => {
            uiEvents.next({
              type: 'RequestSupportWidget',
              from: 'loginPage',
            })
          }}
        >
          <span>{t('common:needHelp')}</span>
        </a>
      </div>
    </LayoutApp>
  )
}
