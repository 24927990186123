import { PricingTier } from './pricing-tiers'
import { OrderModeValue, Venue, VenueFeatures, VenueServiceBrand, VenueTier } from './types/api'
import { unique, keys } from './utils/array'

// this secret is hard coded because it's just a simple measure to avoid external access
// to this endpoint and it gets tree-shaken by Webpack anyway, so it doesn't end up in the web build
//
// Otherwise, we'd need to share it for both backend and web which is a bit of a chore
export const VENUES_SECRET = '7qjOolIikHDkYhY5nhAk2pJ'

export const BLOCK_VENUE_DAYS_PAST_PAYMENT_OVERDUE = 10

export function venueGetOrderingTypes(venueFeatures: VenueFeatures) {
  return (
    venueFeatures.orderingTypes ?? {
      onSite: {
        paymentMethods: {},
      },
    }
  )
}

export function venueGetPaymentMethods(venueFeatures: VenueFeatures) {
  const orderingTypes = venueGetOrderingTypes(venueFeatures)
  return unique([
    ...keys(orderingTypes.onSite?.paymentMethods ?? {}),
    ...keys(orderingTypes.takeaway?.paymentMethods ?? {}),
    ...keys(orderingTypes.delivery?.paymentMethods ?? {}),
  ])
}

export function venueGetOrderingTypePaymentMethods(
  venueFeatures: VenueFeatures,
  orderMode: OrderModeValue,
) {
  const orderingTypes = venueGetOrderingTypes(venueFeatures)
  return orderingTypes[orderMode]?.paymentMethods ?? {}
}

export type VenuePricingTierInput = {
  serviceBrand: VenueServiceBrand
  features: VenueFeatures
  registrationTier?: VenueTier
}
export function venueDetectPricingTier({
  serviceBrand,
  features,
  registrationTier,
}: VenuePricingTierInput): PricingTier {
  const registrationTierIsStore = registrationTier && ['store', 'kiosk'].includes(registrationTier)
  switch (serviceBrand) {
    case 'foodeon':
      if (registrationTierIsStore || venueGetPaymentMethods(features).length > 0) {
        return 'ru_2023_store'
      }
      return 'ru_2023_2_menu'
    case 'foodba':
      if (registrationTierIsStore || venueGetPaymentMethods(features).length > 0) {
        return '2021_tier_c'
      }
      return '2021_tier_d'
  }
}

export function venueDaysAfterPaymentDate(params: {
  trialTill?: string
  paidTill?: string
  differenceInCalendarDays: (d1: Date, d2: Date) => number
}) {
  const { paidTill, trialTill, differenceInCalendarDays } = params
  const activeTill = paidTill ?? trialTill
  if (!activeTill) {
    return 0
  }

  const activeTillDate = new Date(activeTill)
  const now = new Date()

  return differenceInCalendarDays(now, activeTillDate)
}

export function getVenueCurrency(venue: Venue) {
  return venue.features.currency?.code || 'RUB'
}
