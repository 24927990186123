import { Spinner } from './spinner'

export function AppLoader() {
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <div style={{ width: '20%', maxWidth: '10rem' }}>
        <Spinner />
      </div>
    </div>
  )
}
