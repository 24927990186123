import {
  NewOrderRequest,
  NewOrderResponse,
  Product,
  VenueOrderingPaymentMethods,
  OrderLogistics,
  Order,
  OrderModeValue,
  VenueOrderingTypes,
  Venue,
  NewOrderProductModifiers,
} from 'shared/types/api'
import { Tracker } from './tracking'
import { getStorage, usePersistantMap, usePersistantState } from '@/utils/persistant-state-hooks'
import {
  WithFitDescription,
  SelectedPreferences,
  ProductFitDescription,
} from '@/services/preferences'
import { ExcludedElements, excludedElementKeyEncoder } from '@/services/excluded-elements'
import { useVenueContext } from './venue'
import { useBasket } from './basket'
import { useAppContext } from '@/pages/_app'
import {
  useStateFromUiEvents,
  useDefaultObservable,
  uiEvents,
  makeDefaultObservable,
  useClientValue,
} from '@/utils/event'
import { isBrowser, localStorage, sessionStorage } from '@/utils/env'
import { makeInputStorageKey } from '@/components/input'
import { INPUT_PHONE_KEY, INPUT_PHONE_STORAGE } from '@/components/input-phone-storage'
import { NEVER, of, merge } from 'rxjs'
import { TFunction } from 'i18next'
import { map } from 'rxjs/operators'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useDeliveryMap } from '@/components/delivery-map'

export type OrderedProductWithDetails = {
  product: Product
  appliedModifiers: NewOrderProductModifiers
  quantity: number
  fitDescription: ProductFitDescription
  basketProductId?: string
}

export function useOrderManager(venue: Venue) {
  const orderMode = useOrderMode(venue)
  const orderHistory = useOrderHistory(venue.name)

  const currentOrderModeFromStore = useDefaultObservable(orderMode.value$)

  const { value: currentOrderMode, type: orderModeSource } = useClientValue(
    // before the client is loaded, always use this value
    // why: we don't want to show any "you didn't choose the order type" kind of UI
    // before the client load and we can fetch the local browser state
    {
      ...currentOrderModeFromStore,
      isChosenByUser: true,
    },
    // then, rely an the actual value
    currentOrderModeFromStore,
  )

  const { apiClient } = useAppContext()
  const router = useRouter()

  const deliveryMapManager = useDeliveryMap({ venue, orderMode: currentOrderMode })

  const basket = useBasket(venue, currentOrderMode.value, orderModeSource, deliveryMapManager)

  const getServerOrder = async (params: { serverOrderUuid: string }) => {
    const responseOrError = await apiClient.fetch(`/order/${params.serverOrderUuid}`)
    if (responseOrError instanceof Error) {
      return responseOrError
    }

    const order = (await responseOrError.json()) as Order

    return order
  }

  const submitOrder = async (venueName: string, newOrder: NewOrderRequest) => {
    const response = await apiClient.fetch(`/venue/${venueName}/order`, {
      method: 'POST',
      body: JSON.stringify(newOrder),
    })

    if (response instanceof Error) {
      return response
    }

    return (await response.json()) as NewOrderResponse
  }

  // in case the user had paid through the payment gateway but for some reason didn't
  // get redirected to our app, we should get him right to his latest order
  useEffect(() => {
    ;(async () => {
      if (router.route.includes('[orderUuid]')) {
        return
      }
      const historySorted = Array.from(orderHistory.entries()).sort((a, b) => {
        return b[1].timestamp - a[1].timestamp
      })

      if (historySorted.length === 0) {
        return
      }

      // we assume orderUuid === latest can only happen after at least one order
      const [latestOrderKey, latestOrder] = historySorted[0]!
      const statusOnClient = latestOrder.serverOrder?.status.type
      if (statusOnClient !== 'paymentPending') {
        return
      }

      const serverOrder = await getServerOrder({
        serverOrderUuid: latestOrder.serverOrderUuid!,
      })

      if (serverOrder instanceof Error) {
        return
      }

      // status didn't change and it's still paymentPending – no need to redirect the user
      if (serverOrder.status.type === statusOnClient) {
        return
      }

      router.push(`/venue/${venue.name}/order/${latestOrderKey.clientUuid}?w=1`)
    })()
  }, [])

  return {
    orderMode: currentOrderMode,
    orderHistory,
    basket,
    getServerOrder,
    submitOrder,
    deliveryMapManager,
  }
}

declare global {
  interface AppUIEventMap {
    OrderSendAttempt: {
      type: 'OrderSendAttempt'
      params: Omit<SendOrderParams, 'orderHistory' | 'tracker'>
    }
  }
}

export type SendOrderParams = {
  venueName: string
  clientUuid: string
  paymentMethod: keyof VenueOrderingPaymentMethods
  logistics: OrderLogistics
  selectedPreferences: SelectedPreferences
  excludedElements: ExcludedElements
  orderedProducts: OrderedProductWithDetails[]
  orderHistory: Map<OrderHistoryKey, OrderHistoryEntry>
  tracker: Tracker
  orderingTypes: VenueOrderingTypes | undefined
  flatware: ReturnType<typeof useOrderFlatware>
}

export async function sendOrder(
  orderManager: ReturnType<typeof useOrderManager>,
  params: SendOrderParams,
) {
  const {
    venueName,
    clientUuid,
    paymentMethod,
    logistics,
    selectedPreferences,
    excludedElements,
    orderedProducts,
    orderHistory,
    orderingTypes,
    flatware,
  } = params

  uiEvents.next({
    type: 'OrderSendAttempt',
    params: {
      venueName,
      clientUuid,
      paymentMethod,
      logistics,
      selectedPreferences,
      excludedElements,
      orderedProducts,
      orderingTypes,
      flatware,
    },
  })

  const newOrderKey = {
    clientUuid,
  }

  const phoneNumber = getStorage(
    makeInputStorageKey(`${INPUT_PHONE_KEY}/implicit`),
    INPUT_PHONE_STORAGE,
  ).get() as string

  const newOrderEntry: OrderHistoryEntry = {
    timestamp: Date.now(),
    hasPreferences: selectedPreferences.hasPreferences,
    orderedProducts: orderedProducts.map((orderedProduct) => {
      return {
        ...orderedProduct.product,
        quantity: orderedProduct.quantity,
        fitDescription: orderedProduct.fitDescription,
        appliedModifiers: orderedProduct.appliedModifiers,
      }
    }),
    logistics,
    excludedElementsEncoded: {
      ingredients: Array.from(excludedElements.ingredients.entries())
        .filter(([, val]) => {
          return !!val
        })
        .map(([key]) => {
          return excludedElementKeyEncoder.encode(key)
        }),
      rootComponents: Array.from(excludedElements.rootComponents.entries())
        .filter(([, val]) => {
          return !!val
        })
        .map(([key]) => {
          return excludedElementKeyEncoder.encode(key)
        }),
    },
  }

  const { deliveryMapManager } = orderManager
  const { chosenMapAddressImplicit } = deliveryMapManager

  const deliveryZoneId = chosenMapAddressImplicit?.deliveryZoneId as string

  orderHistory.set(newOrderKey, newOrderEntry)
  const newOrderResponse = await orderManager.submitOrder(venueName, {
    products: orderedProducts.map((orderedProduct) => {
      return {
        productName: orderedProduct.product.name,
        productGroupName: orderedProduct.product.productGroupName,
        quantity: orderedProduct.quantity,
        modifiers: orderedProduct.appliedModifiers,
      }
    }),
    paymentMethod: { type: paymentMethod },
    phoneNumber,
    logistics,
    deliveryZoneId,
    flatware:
      flatware.isConfigurable && orderManager.orderMode.value !== 'onSite'
        ? { quantity: flatware.quantity.value }
        : undefined,
  })

  if (newOrderResponse instanceof Error) {
    return { type: 'createOrderHasFailed' }
  }
  orderHistory.set(newOrderKey, {
    ...newOrderEntry,
    serverOrderUuid: newOrderResponse.orderUuid,
  })
  const serverOrder = await orderManager.getServerOrder({
    serverOrderUuid: newOrderResponse.orderUuid,
  })

  if (serverOrder instanceof Error) {
    return { type: 'serverStatusError' }
  }

  orderHistory.set(newOrderKey, {
    ...newOrderEntry,
    serverOrderUuid: newOrderResponse.orderUuid,
    serverOrder: serverOrder,
  })

  if (serverOrder.status.type === 'numberReady') {
    return { type: 'numberReady' }
  }

  if (serverOrder.status.type === 'paymentPending') {
    return { type: 'paymentPending', formUrl: serverOrder.status.formUrl }
  }

  return { type: 'other' }
}

export type OrderHistoryEntry = {
  serverOrderUuid?: string
  serverOrder?: Order
  logistics: OrderLogistics
  timestamp: number // UTC, ms
  hasPreferences: boolean
  excludedElementsEncoded: {
    ingredients: string[]
    rootComponents: string[]
  }
  orderedProducts: WithModifiers<WithFitDescription<WithQuantity<Product>>>[]
}
export type OrderHistoryKey = {
  clientUuid: string
}

export type WithQuantity<T> = T & {
  quantity: number
}

export type WithModifiers<T> = T & {
  appliedModifiers: NewOrderProductModifiers
}

export function useOrderHistory(venueName: string) {
  const storageKey = `order-history/${venueName}`
  const keyEncoder = {
    encode(key: OrderHistoryKey) {
      return key.clientUuid
    },
    decode(encodedKey: string): OrderHistoryKey {
      return {
        clientUuid: encodedKey,
      }
    },
  }
  return usePersistantMap<OrderHistoryKey, OrderHistoryEntry>(storageKey, keyEncoder, {
    schemaVersion: 4,
  })
}

export type OrderMode = {
  isChosenByUser: boolean
  value: OrderModeValue
}

export function useOrderMode(venue: Venue) {
  const router = useRouter()
  const orderingTypes = venue.features.orderingTypes || {
    onSite: {
      paymentMethods: {},
    },
  }

  const availableOrderingTypes = Object.keys(orderingTypes).filter(
    (k): k is Exclude<keyof VenueOrderingTypes, 'default'> => {
      return k !== 'default'
    },
  )

  const urlParamName = 'om'
  const url = isBrowser ? new URL(location.href) : null
  const modeFromUrl = url?.searchParams.get(urlParamName) as OrderModeValue | undefined
  const modeFromUrlValidated =
    modeFromUrl && availableOrderingTypes.includes(modeFromUrl)
      ? (modeFromUrl as OrderModeValue)
      : null

  const defaultMode = (() => {
    if (orderingTypes.default && availableOrderingTypes.includes(orderingTypes.default)) {
      return orderingTypes.default
    }

    return (availableOrderingTypes[0] as OrderModeValue) || 'onSite'
  })()

  const defaultState: OrderMode = {
    isChosenByUser: false,
    value: defaultMode,
  }

  const value$ = useStateFromUiEvents<OrderMode>({
    id: `orderModeValue-${venue.name}`,
    startingValue: defaultState,
    storage: venue.features.alwaysShowOrderModeOverlay ? sessionStorage : localStorage,
    process: (source, scan) => {
      const valueFromUrl = modeFromUrlValidated
        ? of({
            isChosenByUser: true,
            value: modeFromUrlValidated,
          })
        : NEVER
      return merge(
        valueFromUrl,
        source.pipe(
          scan((state, event) => {
            switch (event.type) {
              case 'OrderModeOverlayClick':
                return {
                  ...state,
                  isChosenByUser: true,
                }
              case 'OrderModeTabClick':
                const url = new URL(location.href)
                url.searchParams.set(urlParamName, event.mode)
                router.replace(url.toString(), url.toString(), { shallow: true })

                return {
                  isChosenByUser: true,
                  value: event.mode,
                }
              default:
                return state
            }
          }),
        ),
      ).pipe(
        map((status) => {
          if (!availableOrderingTypes.includes(status.value)) {
            return defaultState
          }

          return status
        }),
      )
    },
  })

  if (!availableOrderingTypes.includes(value$.defaultValue.value)) {
    return { value$: makeDefaultObservable(defaultState, value$) }
  }

  return {
    value$,
  }
}

export function useOrderFlatware() {
  const venueContext = useVenueContext()!
  const { venue } = venueContext
  const defaultQuantity = venue.features.flatware?.defaultQuantity

  const [quantity, setQuantity] = usePersistantState<number>(
    `order/${venue.name}/flatware/quantity`,
    defaultQuantity !== undefined ? defaultQuantity : 1,
  )

  return {
    isConfigurable: !!venue.features.flatware,
    quantity: {
      value: quantity,
      set: setQuantity,
    },
  }
}

export function getCaptionByOrderMode(
  t: TFunction,
  orderMode: OrderModeValue,
  serviceType: Venue['features']['serviceType'],
) {
  switch (orderMode) {
    case 'onSite':
      return serviceType === 'waiter' ? t('common:atRestaurant') : t('common:atCafe')
    case 'delivery':
      return t('common:delivery')
    case 'takeaway':
      return t('common:takeaway')
  }
}
