/* eslint-disable-next-line @typescript-eslint/no-var-requires */
// const tailwindOptions = require('../../web/tailwind.options.js')

const webAppMobileWidth = 428
const webAppMobileTwoColumnsAdminWidth = webAppMobileWidth / 2
const webAppMenuDesktopWidthOneColumn = 844
const webAppMenuDesktopWidth = webAppMenuDesktopWidthOneColumn + 450
const webAppMenuDesktopProductWidth = 387

export {
  webAppMobileWidth,
  webAppMobileTwoColumnsAdminWidth,
  webAppMenuDesktopWidth,
  webAppMenuDesktopProductWidth,
  webAppMenuDesktopWidthOneColumn,
}
