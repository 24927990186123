export class NotFoundError extends Error {
  constructor(error: Error, public response?: Response) {
    super(error.stack || error.message)
  }
}

export class FetchError extends Error {
  constructor(error: Error, public response?: Response) {
    super(error.stack || error.message)
  }
}

export class FetchAbortError extends Error {
  constructor(error: Error, public response?: Response) {
    super(error.stack || error.message)
  }
}
