import { useState, useMemo, useRef } from 'react'
import { join } from 'path'
import { localStorage, isBrowser, isDevBrowser, isDev } from './env'
import { checkSchemaVersionStatus } from './storage'
import { nanoid } from 'nanoid'
import { Tracker } from '@/services/tracking'
import { useAppContext } from '@/pages/_app'
import { FetchError, FetchAbortError, NotFoundError } from './fetch-error'
import { networkInterfaces } from 'os'
import { values } from 'shared/utils/array'
import { Venue } from 'shared/types/api'
import { useRouter } from 'next/router'

export const STORAGE_CACHE_FRAGMENT = 'api-cache'

export type ApiClient = {
  fetch: typeof fetchApi
  setVenue: (venue: Venue) => void
}
export type ApiClientParams = {
  tracker: Tracker
}
export function useApiClient(params: ApiClientParams): ApiClient {
  const venueRef = useRef<Venue | null>(null)
  const router = useRouter()
  const venueNameFromQuery = router.query.venueName as string

  return {
    setVenue(newVenue) {
      /* eslint-disable immutable/no-mutation */
      venueRef.current = newVenue
    },
    fetch: async (path, init) => {
      const requestId = nanoid()
      const fullInit: RequestInit = {
        ...(init || {}),
        headers: {
          ...(init?.headers || {}),
          FoodeonRequestId: requestId,
        },
        credentials: init?.credentials || 'include',
      }

      // the ugliest of hacks
      // why: api.foodeon.com doesn't work well for people from Kazakhstan
      const lcKey = `venue/${venueNameFromQuery}/forceProxyApi`
      const isKazakhVenue =
        venueRef.current?.features.currency?.code.toLowerCase() === 'kzt' ||
        venueRef.current?.features.forceApiProxy ||
        localStorage.getItem(lcKey)

      if (isKazakhVenue) {
        localStorage.setItem(lcKey, 'true')
      }

      const proxyApiHost = `${location.origin}/proxy-api`
      const apiHost = isKazakhVenue && !isDev ? proxyApiHost : undefined
      // force for proxyy everybody to protect against ddos
      // const apiHost = !isDev ? proxyApiHost : undefined
      const responseOrError = await fetchApi(path, { ...fullInit, apiHost })

      const trackError = (fetchError: FetchError, host: string) => {
        const status = fetchError.response?.status
        const severity = status && status >= 500 ? 'critical' : 'info'

        params.tracker.trackError(fetchError, {
          apiError: true,
          host,
          path,
          body: init?.body,
          FoodeonRequestId: requestId,
          status,
          severity,
        })
      }

      if (!(responseOrError instanceof FetchError)) {
        return responseOrError
      }

      trackError(responseOrError, 'api')

      const responseOrErrorProxy = await fetchApi(path, { ...fullInit, apiHost: proxyApiHost })
      if (responseOrErrorProxy instanceof FetchError) {
        trackError(responseOrErrorProxy, 'proxy')
      }

      return responseOrErrorProxy
    },
  }
}

export type ApiResponse<T> = {
  sameAsCache: boolean
  value: T | Error
}

export type RequestInitGetOnly = RequestInit & {
  method?: 'GET'
}
type Fetch = (path: string, init?: RequestInitGetOnly | undefined) => Promise<Response | Error>

export async function fetchApi(
  path: string,
  init?: RequestInit & {
    apiHost?: string
  },
) {
  const apiHost = init?.apiHost ?? getApiHost()
  const fullUrl = new URL(path.startsWith('http') ? path : join(apiHost, path))

  const responseOrError = await fetch(fullUrl.toString(), init).catch((e) => {
    return e as Error
  })

  if (responseOrError instanceof Error) {
    if (responseOrError.name === 'AbortError') {
      return new FetchAbortError(responseOrError)
    }
    return new FetchError(responseOrError)
  }

  if (responseOrError.status === 404) {
    return new NotFoundError(new Error(`404 for URL=${fullUrl.toString()}`), responseOrError)
  }

  if (responseOrError.status > 404) {
    return new FetchError(
      new Error(`Bad response status. URL=${fullUrl.toString()}, status=${responseOrError.status}`),
      responseOrError,
    )
  }

  return responseOrError
}

export function useStaleWhileRevalidate<T>(
  path: string,
  options: {
    schemaVersion?: number
    dummy?: boolean
    runtimeVersion?: number
    onApiResponse?: (val: T, isSameAsCache: boolean) => void
  } = {},
) {
  const { apiClient } = useAppContext()
  const [resultFromApi, setResultFromApi] = useState<T | Error | null>(null)

  const currentRequest = useRef({
    abortController: null as null | AbortController,
  })

  const resultFromCache = useMemo(() => {
    if (options.dummy) {
      return null
    }
    const fetchWithCache = withLocalCache<T>(apiClient.fetch, {
      schemaVersion: options.schemaVersion,
    })
    if (currentRequest.current.abortController) {
      currentRequest.current.abortController.abort()
    }
    const abortController = isBrowser ? new AbortController() : null
    // mutable by design
    // eslint-disable-next-line immutable/no-mutation
    currentRequest.current.abortController = abortController
    const request = fetchWithCache(path, {
      signal: abortController?.signal,
    })
    request.api
      .then((res) => {
        if (!(res.value instanceof Error) && options.onApiResponse) {
          options.onApiResponse(res.value, res.sameAsCache)
        }
        if (res.sameAsCache) {
          return
        }
        return setResultFromApi(res.value)
      })
      .catch((e) => {
        throw new Error(`unexpected error in useStaleWhileRevalidate\n${e.stack || e.message}`)
      })

    return request.cache
  }, [path, options.runtimeVersion])

  if (resultFromApi instanceof Error && resultFromCache) {
    return resultFromCache
  }

  return resultFromApi || resultFromCache
}

export function getApiHost() {
  if (!isBrowser && process?.env?.NODE_ENV !== 'production') {
    const localIpAddress = getLocalIp()

    if (!localIpAddress) {
      throw new Error(`couldn't detect the local ip address`)
    }

    return `http://${localIpAddress}:4000`
  }

  if (isDevBrowser) {
    return `http://${location.hostname}:4000`
  }

  if (isBrowser && ['foodeon.com', 'foodba.com'].includes(location.hostname)) {
    return `https://api.${location.hostname}`
  }

  return 'https://api.foodba.com'
}

export function getWebHost() {
  if (!isBrowser && process?.env?.NODE_ENV !== 'production') {
    const localIpAddress = getLocalIp()

    if (!localIpAddress) {
      throw new Error(`couldn't detect the local ip address`)
    }

    return `http://${localIpAddress}:3000`
  }
  if (isDevBrowser) {
    return `http://${location.host}`
  }

  return 'https://foodeon.com'
}

function getLocalIp() {
  const nets = networkInterfaces()
  return values(nets)
    .flat()
    .find((net) => {
      return (
        !net.internal &&
        (net.address.startsWith('192.') ||
          net.address.startsWith('172.') ||
          net.address.startsWith('10.'))
      )
    })?.address
}

function withLocalCache<T>(
  fetchApi: Fetch,
  options: {
    schemaVersion?: number
  },
): (
  path: string,
  init?: RequestInitGetOnly | undefined,
) => { api: Promise<ApiResponse<T>>; cache: T | null } {
  return (path, init?) => {
    const storageKey = `${STORAGE_CACHE_FRAGMENT}-${path}`
    const schemaVersionStatus = checkSchemaVersionStatus(
      storageKey,
      options.schemaVersion,
      localStorage,
    )
    if (schemaVersionStatus === 'outdated') {
      localStorage.removeItem(storageKey)
    }

    const cachedStr = localStorage.getItem(storageKey)
    const cache = cachedStr ? (JSON.parse(cachedStr) as T) : null

    const apiCallPromise = isBrowser
      ? fetchApi(path, init).then(async (res) => {
          if (res instanceof Error) {
            return res
          }
          const bodyRaw = await res.text()
          localStorage.setItem(`${STORAGE_CACHE_FRAGMENT}-${path}`, bodyRaw)
          return bodyRaw
        })
      : Promise.resolve(cachedStr!)

    return {
      api: apiCallPromise.then((bodyRaw) => {
        const value = bodyRaw instanceof Error ? bodyRaw : (JSON.parse(bodyRaw) as T)
        return {
          value,
          sameAsCache: bodyRaw === cachedStr,
        }
      }),
      cache,
    }
  }
}
