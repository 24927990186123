import React, { useEffect, useState, useRef, Ref, forwardRef, useImperativeHandle } from 'react'
import { MapboxMapConfig } from 'shared/types/api'
import { useAppContext } from '@/pages/_app'
import mapboxgl, { Map, NavigationControl, GeolocateControl, LngLat } from 'mapbox-gl'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { truly } from 'shared/utils/types'
import { BottomButton } from './bottom-button'
import { MapAddress, MapScreenRef } from '@/services/maps'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import Head from 'next/head'
import { delay } from 'shared/utils/async'
import hash from 'object-hash'

export type MapboxScreenProps = {
  config: MapboxMapConfig
  onSelect: (address: MapAddress | null) => void
}

type NominatimAddress = {
  house_number?: string
  road?: string
  neighbourhood?: string
  suburb?: string
  postcode?: string
  city: string
  town: string
  city_district?: string
  county?: string
  state: string
  country: string
  country_code: string
  continent?: string
  public_building?: string
  attraction?: string
  pedestrian?: string
  peak?: string
  bakery?: string
  electronics?: string
  construction?: string
  building?: string
  amenity?: string
  tourism?: string
}

type NominatimSearchResult = {
  place_id: string
  osm_id: string
  boundingbox?: string[4]
  lat: string
  lng: string
  display_name: string
  class: string
  type: string
  importance: number
  icon: string
  address?: NominatimAddress
  licence: string
  svg?: string
  name?: string
}

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiZm9vZGJhIiwiYSI6ImNrazZvZm5zOTA1eG8yd28wbnlibnhkbzcifQ.WC03n6JN-0TJzXyoxCIFuA'

export const MapboxScreen = forwardRef(MapboxScreenWithRef)
function MapboxScreenWithRef(props: MapboxScreenProps, ref: Ref<MapScreenRef>) {
  const { apiClient, t } = useAppContext()
  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const mapboxMapRef = useRef<Map | null>(null)
  const addressCaptionRef = useRef<HTMLDivElement>(null)
  const [chosenAddress, setChosenAddress] = useState<MapAddress | null>(null)
  const correctAddressIsChosen = !!chosenAddress?.deliveryZoneId

  const mapFeaturesHash = hash(props.config.featureCollection.features)

  const chooseAddress = async (coords: LngLat) => {
    const map = mapboxMapRef.current
    if (!map) {
      return
    }

    const deliveryZonesUnderPinpoint = map.queryRenderedFeatures(map.project(coords), {
      layers: ['delivery'],
    })

    const deliveryZoneId = deliveryZonesUnderPinpoint[0]?.properties?.id?.toString()

    // const mapBoxGeocodingUrl = new URL(`https://api.mapbox.com/geocoding/v5/mapbox.places/${center.lng.toString()},${center.lat.toString()}.json`)
    const mapBoxGeocodingUrl = new URL(
      `https://nominatim.openstreetmap.org/reverse.php?zoom=18&format=jsonv2`,
    )
    mapBoxGeocodingUrl.searchParams.append('lat', coords.lat.toString())
    mapBoxGeocodingUrl.searchParams.append('lon', coords.lng.toString())

    // mapBoxGeocodingUrl.searchParams.append('access_token', MAPBOX_ACCESS_TOKEN)

    const response = await apiClient.fetch(mapBoxGeocodingUrl.toString(), {
      credentials: 'omit',
    })

    if (response instanceof Error) {
      console.log('Mapbox API error', response)
      return
    }

    const body = (await response.json()) as NominatimSearchResult

    const { address } = body
    const city = address?.city ?? address?.town
    const house = address?.building ?? address?.house_number ?? address?.amenity ?? address?.tourism
    const text = [house ?? body.name, address?.road, city].filter(truly).join(', ')

    setChosenAddress({
      coords: [coords.lng, coords.lat],
      text: deliveryZoneId !== undefined ? text : t('common:addressOutsideDeliveryArea'),
      city,
      street: address?.road,
      house,
      deliveryZoneId,
      mapFeaturesHash,
    })
  }

  useImperativeHandle(ref, () => {
    return {
      chooseAddress(coords) {
        const map = mapboxMapRef.current
        if (!map) {
          return
        }
        map.jumpTo({
          center: coords,
          zoom: 15,
        })
      },
    }
  })

  useEffect(() => {
    if (!mapContainerRef.current) {
      return
    }

    const map = new Map({
      accessToken: MAPBOX_ACCESS_TOKEN,
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: props.config.zoom,
      center: props.config.center,
    })

    const geocoder = new MapboxGeocoder({
      accessToken: MAPBOX_ACCESS_TOKEN,
      mapboxgl,
      flyTo: {
        maxDuration: 0.5,
      },
      marker: false,
    })

    geocoder.on('result', () => {
      geocoder.setInput('')
    })

    map.on('moveend', async () => {
      // this wait is important because without it mapbox doesn't always understand that the new address
      // is within the delivery area when using the search
      await delay(100)
      chooseAddress(map.getCenter()).catch((err) => {
        console.error('choosAddress error', err)
      })
    })

    map
      .addControl(geocoder)
      .addControl(new NavigationControl(), 'bottom-right')
      .addControl(new GeolocateControl(), 'bottom-right')

    // remounting the address caption element inside Mapbox so that z-index works well
    const canvasContainer = document.querySelector(`.mapboxgl-canvas-container`)!
    canvasContainer.appendChild(addressCaptionRef.current!)

    map.on('load', () => {
      map.addSource('delivery', {
        type: 'geojson',
        data: props.config.featureCollection as GeoJSON.FeatureCollection,
      })
      map.addLayer({
        id: 'delivery',
        type: 'fill',
        source: 'delivery',
        layout: {},
        paint: {
          'fill-color': '#088',
          'fill-opacity': 0.2,
        },
      })
      map.addLayer({
        id: 'delivery-stroke',
        type: 'line',
        source: 'delivery',
        layout: {},
        paint: {
          'line-color': '#088',
          'line-width': 2,
        },
      })
    })

    // eslint-disable-next-line immutable/no-mutation
    mapboxMapRef.current = map
  }, [])

  return (
    <div className="h-full">
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <link href="https://api.mapbox.com/mapbox-gl-js/v2.0.0/mapbox-gl.css" rel="stylesheet" />
      </Head>

      <style>{`canvas {
        outline: none;
      }
      
      .mapboxgl-ctrl-top-right {
        width: 100%;
        padding: 10px;
      }

      .mapboxgl-ctrl-geocoder {
        width: 100%;
        margin: 0 !important;
      }

      .mapboxgl-ctrl-geocoder--input {
        height: 50px;
        padding: 6px 45px;
      }

      .mapboxgl-ctrl-geocoder--icon {
        top: 13px;
        left: 12px;
        width: 23px;
        height: 23px;
      }

      .mapboxgl-ctrl-geocoder--icon-close {
        margin-top: 5px;
      }

      .mapboxgl-ctrl-bottom-right {
        margin-bottom: 10px;
      }
      `}</style>

      <div className="h-full" style={{ paddingBottom: '72px' }}>
        <div
          ref={addressCaptionRef}
          className="flex absolute w-full flex-col justify-end items-center"
          style={{
            top: '74px',
          }}
        >
          <div
            className="px-3 py-2 rounded-lg text-2xl text-center text-opaqueStrong"
            style={{
              width: '95%',
              background: 'rgba(255, 255, 255, 0.75)',
            }}
          >
            {chosenAddress?.text || t('delivery:provideAddressSoWeKnowWhereToDevlierOrder')}
          </div>
        </div>

        <div
          className="absolute"
          style={{
            top: '50%',
            left: '50%',
            border: '1px',
            marginTop: `-72px`,
            marginLeft: `-13px`,
            zIndex: 1,
          }}
        >
          <CenterMark />
        </div>

        <div className="h-full" ref={mapContainerRef}></div>
      </div>

      <BottomButton className="absolute">
        <div
          onClick={() => {
            return props.onSelect(correctAddressIsChosen ? chosenAddress : null)
          }}
          className="flex items-center h-12 text-lg justify-center rounded-lg bg-back dark:bg-backDark cursor-pointer font-medium"
        >
          <span>{correctAddressIsChosen ? t('common:chooseAddress') : t('common:back')}</span>
        </div>
      </BottomButton>
    </div>
  )
}

function CenterMark() {
  return (
    <svg width="29" height="38" viewBox="0 0 42 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M21.0005 0C11.6115 0 4 7.95343 4 16.749C4 29.1385 20.6507 46.346 21.0005 46.499C21.3493 46.652 38 29.1385 38 16.749C38 7.49493 30.3894 0 21.0005 0Z"
          fill="white"
        />
        <path
          d="M21.0004 2C12.716 2 6 9.18374 6 17.1281C6 28.3187 20.6918 43.8609 21.0004 43.9991C21.3082 44.1373 36 28.3187 36 17.1281C36 8.76962 29.2848 2 21.0004 2ZM21.0004 26.3153C16.083 26.3153 12.0964 22.295 12.0964 17.336C12.0964 12.3762 16.083 8.35591 21.0004 8.35591C25.9174 8.35591 29.9036 12.3762 29.9036 17.336C29.9036 22.295 25.9174 26.3153 21.0004 26.3153Z"
          fill="#4D4D4D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="42"
          height="54.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
