import { useEffect, useState, useRef, Ref, forwardRef, useImperativeHandle } from 'react'
import { VenueBottomButton } from '@/components/bottom-button'
import { YandexMapConfig } from 'shared/types/api'
import Head from 'next/head'
import { useAppContext } from '@/pages/_app'
import { MapAddress, MapScreenRef } from '@/services/maps'
import hash from 'object-hash'

export type YandexDeliveryScreenProps = {
  config: YandexMapConfig
  onSelect: (address: MapAddress | null) => void
}

export const YandexDeliveryScreen = forwardRef(YandexDeliveryScreenWithRef)
function YandexDeliveryScreenWithRef(props: YandexDeliveryScreenProps, ref: Ref<MapScreenRef>) {
  const { t } = useAppContext()

  // this code works with an untyped API from Yandex
  // any is allowed for the purpose of faster development and isn't propagated outside of this file
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const [ymapsIsLoaded, setYmapsIsLoaded] = useState(false)
  const [ymaps, setYmaps] = useState<any | null>(null)
  const [chosenAddress, setChosenAddress] = useState<MapAddress | null>(null)
  const [chooseAddressFn, setChooseAddressFn] = useState<any>(null)
  const [mapControlObject, setMapControlObject] = useState<any>(null)

  const mapContainerRef = useRef<HTMLDivElement>(null)
  const yandexAddressCaptionRef = useRef<HTMLDivElement>(null)

  const mapFeaturesHash = hash(props.config.featureCollection.features)

  useImperativeHandle(ref, () => {
    return {
      chooseAddress(coords) {
        ymaps
          .geocode(coords)
          .then((response: any) => {
            const geoObject = response.geoObjects.get(0)
            if (geoObject) {
              chooseAddressFn(geoObject)
              const mapContainer = mapContainerRef.current!
              const bounds = geoObject.properties.get('boundedBy')
              const mapState = ymaps.util.bounds.getCenterAndZoom(bounds, [
                mapContainer.offsetWidth,
                mapContainer.offsetHeight,
              ])
              mapControlObject.setCenter(mapState.center, mapState.zoom)
            }
            return
          })
          .catch(() => {
            console.error('geocode error')
          })
      },
    }
  })

  useEffect(() => {
    const script = document.createElement('script')
    // Obeying the browser API
    // eslint-disable-next-line immutable/no-mutation
    script.src =
      'https://api-maps.yandex.ru/2.1/?lang=ru_RU&coordorder=longlat&apikey=204a10d0-73a3-4299-b065-b97fec18b44a'
    script.addEventListener('load', function () {
      setYmapsIsLoaded(true)
    })
    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    const ymaps = (window as any).ymaps
    if (!ymaps || !ymapsIsLoaded) {
      return
    }

    ymaps.ready(() => {
      setYmaps(ymaps)
    })
  }, [ymapsIsLoaded])

  useEffect(() => {
    if (!ymaps) {
      return
    }

    const mapControl = new ymaps.Map(
      'ymap',
      {
        center: props.config.center,
        zoom: props.config.zoom,
        controls: ['geolocationControl', 'searchControl', 'zoomControl'],
      },
      {
        suppressMapOpenBlock: true,
        yandexMapDisablePoiInteractivity: true,
      },
    )
    setMapControlObject(mapControl)

    const searchControl = mapControl.controls.get('searchControl')
    searchControl.options.set({
      noPlacemark: true,
      placeholderContent: t('delivery:enterStreetAndHouseNumber'),
      size: 'large',
    })

    const zoomControl = mapControl.controls.get('zoomControl')
    zoomControl.options.set({
      size: 'small',
    })

    const deliveryZones = props.config.featureCollection
      ? ymaps.geoQuery(props.config.featureCollection).addToMap(mapControl)
      : null
    deliveryZones?.each((zone: any) => {
      zone.options.set({
        fillColor: zone.properties.get('color'),
        fillOpacity: zone.properties.get('fill-opacity'),
        strokeColor: zone.properties.get('color'),
      })
    })

    const chooseAddress = (geoObject: any) => {
      const coords = geoObject.geometry.getCoordinates()

      const meta = geoObject.properties.get('metaDataProperty.GeocoderMetaData')

      const addressComponents = meta.Address.Components
      const district = addressComponents.find((c: any) => {
        return c.kind === 'district'
      })?.name
      const street =
        addressComponents.find((c: any) => {
          return c.kind === 'street'
        })?.name || district
      const house = addressComponents.find((c: any) => {
        return c.kind === 'house'
      })?.name
      const entrance = addressComponents.find((c: any) => {
        return c.kind === 'entrance'
      })?.name
      const cityComponents = addressComponents
        .filter((c: any) => {
          return c.kind === 'locality'
        })
        .map((c: any) => {
          return c.name
        })
      const city = cityComponents.join(', ')

      const name = geoObject.properties.get('name')
      const address = [
        name,
        ...cityComponents.filter((c: any) => {
          return !name.includes(c)
        }),
      ].join(', ')

      const deliveryZone = deliveryZones?.searchContaining(coords).get(0)
      const deliveryZoneId = deliveryZone?.properties.get('id') ?? undefined

      const addressIsOffBounds = deliveryZoneId === undefined
      searchControl.clear()

      setChosenAddress({
        deliveryZoneId,
        text: addressIsOffBounds ? t('common:addressOutsideDeliveryArea') : address,
        coords,
        city,
        street,
        house,
        entrance,
        mapFeaturesHash,
      })
    }
    setChooseAddressFn(() => {
      return chooseAddress
    })

    mapControl.controls
      .get('geolocationControl')
      .events.add('locationchange', function (event: any) {
        const geoObject = event.get('geoObjects').get(0)
        chooseAddress(geoObject)
      })

    searchControl.events.add('resultshow', async (event: any) => {
      const geoObject = searchControl.getResultsArray()[event.get('index')]
      chooseAddress(geoObject)
    })

    mapControl.events.add('actionend', function () {
      const projection = mapControl.options.get('projection')
      const mapState = mapControl.action.getCurrentState()
      const centerCoords = projection.fromGlobalPixels(mapState.globalPixelCenter, mapState.zoom)

      ymaps
        .geocode(centerCoords, { kind: 'house' })
        .then((response: any) => {
          const geoObject = response.geoObjects.get(0)
          if (geoObject) {
            chooseAddress(geoObject)
          }
          return
        })
        .catch(() => {
          console.error('geocode error')
        })
    })

    const version = ymapsVersion(ymaps)

    // remounting the address caption element inside Yandex Maps so that z-index works well
    const innerPanes = document.querySelector(`.ymaps-${version}-inner-panes`)!
    innerPanes.appendChild(yandexAddressCaptionRef.current!)
  }, [ymaps])

  const correctAddressIsChosen = chosenAddress?.deliveryZoneId !== undefined

  const version = ymaps ? ymapsVersion(ymaps) : null

  return (
    <div className="h-full">
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      {version ? (
        <style>{`
          .ymaps-${version}-map {
            height: 100% !important
          }
          .ymaps-${version}-searchbox__normal-layout {
            width: ${document.getElementById('mapContainer')!.offsetWidth - 70}px !important
          }
          .ymaps-${version}-controls__toolbar .ymaps-${version}-float-button {
            width: 40px;
            height: 40px;
          }
          .ymaps-${version}-float-button-icon_icon_geolocation {
            width: 40px;
            height: 40px;
            box-sizing: border-box !important;
          }
          .ymaps-${version}-searchbox-input__input {
            height: 40px !important;
            font-size: 15px !important;
          }
          .ymaps-${version}-searchbox-button {
            height: 40px;
          }
          .ymaps-${version}-searchbox-button-text {
            line-height: 40px;
          }
          `}</style>
      ) : null}
      <div className="h-full pb-15">
        <div
          className="absolute z-50"
          style={{
            top: '50%',
            left: '50%',
            border: '1px',
            marginTop: `-30px`,
            marginLeft: `-15px`,
          }}
        >
          <CenterMark />
        </div>
        <div
          ref={yandexAddressCaptionRef}
          className="flex absolute w-full flex-col justify-end items-center"
          style={{ top: '64px', zIndex: 2000 }}
        >
          <div
            className="px-3 py-2 rounded-lg text-2xl text-center text-opaqueStrong leading-7"
            style={{
              width: '70%',
              background: 'rgba(255, 255, 255, 0.75)',
            }}
          >
            {chosenAddress?.text || t('delivery:provideAddressSoWeKnowWhereToDevlierOrder')}
          </div>
        </div>
        <div ref={mapContainerRef} id="ymap" className="h-full"></div>
      </div>
      <VenueBottomButton className="absolute">
        <div
          onClick={() => {
            return props.onSelect(correctAddressIsChosen ? chosenAddress : null)
          }}
          className="flex items-center h-12 text-lg justify-center rounded-lg bg-back dark:bg-backAccentDark dark:text-primaryAccentDark cursor-pointer font-medium"
        >
          <span>{correctAddressIsChosen ? t('common:chooseAddress') : t('common:back')}</span>
        </div>
      </VenueBottomButton>
    </div>
  )
}

function CenterMark() {
  return (
    <svg width="29" height="38" viewBox="0 0 42 55" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M21.0005 0C11.6115 0 4 7.95343 4 16.749C4 29.1385 20.6507 46.346 21.0005 46.499C21.3493 46.652 38 29.1385 38 16.749C38 7.49493 30.3894 0 21.0005 0Z"
          fill="white"
        />
        <path
          d="M21.0004 2C12.716 2 6 9.18374 6 17.1281C6 28.3187 20.6918 43.8609 21.0004 43.9991C21.3082 44.1373 36 28.3187 36 17.1281C36 8.76962 29.2848 2 21.0004 2ZM21.0004 26.3153C16.083 26.3153 12.0964 22.295 12.0964 17.336C12.0964 12.3762 16.083 8.35591 21.0004 8.35591C25.9174 8.35591 29.9036 12.3762 29.9036 17.336C29.9036 22.295 25.9174 26.3153 21.0004 26.3153Z"
          fill="#4D4D4D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="42"
          height="54.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

function ymapsVersion(ymaps: { meta: { version: string } }) {
  return ymaps.meta.version.replace(/\./g, '-')
}
