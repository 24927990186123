import { useAppContext } from '@/pages/_app'
import { useGdprConsent } from '@/services/gdpr-consent'
import { useVenueContext } from '@/services/venue'
import { isBrowser } from '@/utils/env'
import { uiEvents, useDefaultObservable } from '@/utils/event'
import { webAppMenuDesktopWidthOneColumn } from 'shared/misc'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

declare global {
  interface AppUIEventMap {
    GdprConsentAgreementClick: {
      type: 'GdprConsentAgreementClick'
    }
  }
}

export function GdprConsent() {
  const { t } = useAppContext()
  const { venueName } = useVenueContext()!
  const [width, setWidth] = useState<number | null>(null)

  const { userAgreedToCookies$ } = useGdprConsent()
  const userAgreedToCookies = useDefaultObservable(userAgreedToCookies$)

  useEffect(() => {
    const layoutWidth = document.getElementById('layout')!.offsetWidth
    setWidth(Math.min(layoutWidth, webAppMenuDesktopWidthOneColumn))
  }, [])

  if (!isBrowser || !width || userAgreedToCookies) {
    return null
  }

  return (
    <div className="fixed left-0 bottom-0 w-full z-40" style={{ minHeight: '5rem' }}>
      <div
        className="mx-auto h-full px-5 pt-3 pb-5 rounded-t-lg"
        style={{
          width,
          boxShadow: '0px -5px 4px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#5c5c5c',
        }}
      >
        <div
          className="flex items-center h-12 text-lg justify-center rounded-lg cursor-pointer"
          style={{
            backgroundColor: '#ffbc33',
          }}
          onClick={() => {
            return uiEvents.next({
              type: 'GdprConsentAgreementClick',
            })
          }}
        >
          <span>{t('gdprConsent:iAgree')}</span>
        </div>
        <div className="mt-3 text-back dark:text-backDark leading-tight">
          <Trans
            t={t}
            i18nKey="gdprConsent:weUseCookies"
            components={{
              policyLink: <a className="underline" href={`/venue/${venueName}/terms`} />,
            }}
          />
        </div>
      </div>
    </div>
  )
}
