import { useStaleWhileRevalidate } from '../utils/fetch'
import {
  GetPreferences,
  Product,
  ProductGroup,
  IsRemovable,
  Component,
  ProductIngredient,
  FoodRestriction,
} from 'shared/types/api'
import intersection from 'lodash/intersection'
import { usePersistantMap, usePersistantState } from '@/utils/persistant-state-hooks'
import { useVenueContext } from './venue'
import { truly } from 'shared/utils/types'

export function useAvailablePreferences() {
  return useStaleWhileRevalidate<GetPreferences.AvailablePreferences>('/preferences')
}

export function useSelectedPreferences(): SelectedPreferences {
  const allergens = useSelectedAllergens()
  const foodRestrictions = useSelectedFoodRestrictions()
  const unwantedComponents = useUnwantedComponents()

  return {
    hasPreferences: allergens.size + foodRestrictions.size + unwantedComponents.size > 0,
    allergens,
    foodRestrictions,
    unwantedComponents,
  }
}

const nameEncoder = {
  decode(encodedKey: string) {
    return {
      name: encodedKey,
    }
  },
  encode(key: { name: string }) {
    return key.name
  },
}

export function useSelectedFoodRestrictions() {
  return usePersistantMap<{ name: string }, true>('selected-food-restrictions/map', nameEncoder)
}

export function useSelectedAllergens() {
  return usePersistantMap<{ name: string }, true>('selected-allergens/map', nameEncoder)
}

export function useUnwantedComponents() {
  return usePersistantMap<{ name: string }, true>('unwanted-components/map', nameEncoder)
}

export type PreferenceMode = 'filter' | 'sort'

export function useSelectedPreferenceMode() {
  const venueContext = useVenueContext()!
  const { venueName } = venueContext
  return usePersistantState<PreferenceMode>(`preference-mode/${venueName}`, 'filter')
}

export type WithFitDescription<T> = T & {
  fitDescription: ProductFitDescription
}

export type ProductGroupAdaptable = {
  products: WithFitDescription<Product>[]
} & ProductGroup

export type ProductFitDescription = {
  productFitsPreferences: boolean
  ingredients: IsRemovable<ProductIngredient>[]
  rootComponents: IsRemovable<Component>[]
}

export type SelectedPreferences = {
  hasPreferences: boolean
  allergens: Map<{ name: string }, true>
  foodRestrictions: Map<{ name: string }, true>
  unwantedComponents: Map<{ name: string }, true>
}
export function augmentProductsByPreferences(
  products: Product[],
  availablePreferences: GetPreferences.AvailablePreferences,
  selectedPreferences: SelectedPreferences,
) {
  const productsWithFitDescription: WithFitDescription<Product>[] = products.map((product) => {
    return {
      ...product,
      fitDescription: getProductElementsNotFittingPreferences(
        product,
        availablePreferences,
        selectedPreferences,
      ),
    }
  })

  return productsWithFitDescription
}

export function getProductElementsNotFittingPreferences(
  product: Product,
  availablePreferences: GetPreferences.AvailablePreferences,
  selectedPreferences: SelectedPreferences,
): ProductFitDescription {
  const selectedFoodRestrictions = Array.from(selectedPreferences.foodRestrictions.keys())
    .map((selected) => {
      return availablePreferences.allFoodRestrictions.find((fr) => {
        return fr.name === selected.name
      })
    })
    .filter(truly)

  const causingAllergy = (component: Component) => {
    return (
      intersection(
        component.allergens.map((a) => {
          return a.name
        }),
        Array.from(selectedPreferences.allergens.keys()).map((k) => {
          return k.name
        }),
      ).length > 0
    )
  }

  const isExcluded = (component: Component) => {
    return selectedPreferences.unwantedComponents.has({ name: component.name })
  }

  const breakingFoodRestrictions = (component: Component) => {
    return !isComponentFittingFoodRestrictions(component, selectedFoodRestrictions)
  }

  const ingredientsCausingAllergies = product.ingredients.filter((ingredient) => {
    return ingredient.components.some(causingAllergy)
  })

  const rootComponentsCausingAllergies = product.components.filter(causingAllergy)

  const ingredientsWithExcludedComponents = product.ingredients.filter((ingredient) => {
    return ingredient.components.some(isExcluded)
  })

  const excludedRootComponents = product.components.filter(isExcluded)

  const ingredientsWithFoodRestrictions = product.ingredients.filter((ingredient) => {
    return ingredient.components.some(breakingFoodRestrictions)
  })

  const rootComponentsWithFoodRestrictions = product.components.filter(breakingFoodRestrictions)

  const ingredients = [
    ...ingredientsWithFoodRestrictions,
    ...ingredientsCausingAllergies,
    ...ingredientsWithExcludedComponents,
  ].map((ingredient) => {
    return {
      ...ingredient,
      components: ingredient.components.filter((c) => {
        return causingAllergy(c) || isExcluded(c) || breakingFoodRestrictions(c)
      }),
    }
  })

  const rootComponents = [
    ...rootComponentsWithFoodRestrictions,
    ...rootComponentsCausingAllergies,
    ...excludedRootComponents,
  ]

  const productFitsPreferences =
    [...ingredients, ...rootComponents].filter((v) => {
      return !v.isRemovable
    }).length === 0

  return {
    productFitsPreferences,
    ingredients,
    rootComponents,
  }
}

export function isComponentFittingFoodRestrictions(
  component: Component,
  selectedFoodRestrictions: FoodRestriction[],
) {
  for (const foodRestriction of selectedFoodRestrictions) {
    const componentIncludesFoodRestriction = component.foodRestrictions
      .map((fr) => {
        return fr.name
      })
      .includes(foodRestriction.name)

    if (foodRestriction.filterType === 'inclusive' && !componentIncludesFoodRestriction) {
      return false
    }

    if (foodRestriction.filterType === 'exclusive' && componentIncludesFoodRestriction) {
      return false
    }
  }
  return true
}
