import { filterEvents, uiEvents } from '@/utils/event'
import { useRouter } from 'next/router'
import { createContext, PropsWithChildren, useEffect } from 'react'
import { useObservable } from 'rxjs-hooks'
import { map } from 'rxjs/operators'

declare global {
  interface AppUIEventMap {
    LayoutForceMobileView: {
      type: 'LayoutForceMobileView'
      state: boolean
    }
    LayoutResize: {
      type: 'LayoutResize'
      forceMobileLayout: boolean
    }
  }
}

export function Layout({ children }: PropsWithChildren<unknown>) {
  const router = useRouter()

  if (
    // this is dirty, but let's gather more cases before introducing a true way
    router.route.startsWith('/admin/venue/[venueName]/settings') ||
    router.route.startsWith('/admin/venue/[venueName]/pages') ||
    router.route === '/admin/venue/[venueName]'
  ) {
    return <div id="layout">{children}</div>
  }

  return <LayoutApp>{children}</LayoutApp>
}

export type LayoutAppProps = PropsWithChildren<unknown>

export const LayoutContext = createContext<{ forceMobileLayout: boolean }>({
  forceMobileLayout: false,
})

export function LayoutApp(props: LayoutAppProps) {
  const router = useRouter()

  const forceMobileLayout = useObservable(() => {
    return uiEvents.pipe(
      filterEvents(['LayoutForceMobileView']),
      map((event) => {
        return event.state
      }),
    )
  }, false)

  useEffect(() => {
    uiEvents.next({ type: 'LayoutResize', forceMobileLayout })
  }, [forceMobileLayout])

  return (
    <div
      id="layout"
      className={`mx-auto pb-20 font-sans bg-back dark:bg-backDark border-b border-back text-primary dark:text-primaryDark shadow-2xl min-h-screen max-w-webAppMobileWidth ${
        (router.route === '/venue/[venueName]' || router.route === '/') && !forceMobileLayout
          ? 'md:max-w-webAppMenuDesktopWidth md:shadow-none'
          : ''
      }`}
    >
      <LayoutContext.Provider value={{ forceMobileLayout }}>
        {props.children}
      </LayoutContext.Provider>
    </div>
  )
}
