import '../tailwind.css'
import { AppPropsType } from 'next/dist/shared/lib/utils'
import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { registerTracker, Tracker, trackErrors } from '@/services/tracking'
import { isBrowser } from '@/utils/env'
import { useConstant } from '@/utils/use-constant'
import { filterEvents, uiEvents } from '@/utils/event'
import fromEntries from 'object.fromentries'
import { useVenueContext, VenueContextController } from '@/services/venue'
import { ErrorBoundary } from '@/components/error-boundary'
import { useApiClient, ApiClient } from '@/utils/fetch'
import Head from 'next/head'
import i18next, { i18n, StringMap, TOptions } from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import en from '@root/public/lang/en.json'
import { AppLoader } from '@/components/app-loader'
import { Layout } from '@/components/layout'
import { mapTo } from 'rxjs/operators'
import { useObservable } from 'rxjs-hooks'
import { NextRouter, useRouter } from 'next/router'
import { PageConfig } from '@/components/page'
import { AdminLoginManual } from '@/components/admin-login-manual'

import('intersection-observer')
import('lazysizes')
import('abort-controller/polyfill')

if (process.env.NODE_ENV === 'development') {
  import('@impulse.dev/runtime')
    .then((mod) => {
      return mod.run({
        prettierConfig: require('@root/.prettierrc.js'),
        config: { editorLinkSchema: 'neovide' },
      })
    })
    .catch((e) => {
      return console.error(e)
    })
}

// ua-parser is adding considerable weigth to the app but it's not critical to load it ASAP
const UAParserPromise = import('ua-parser-js')

declare global {
  interface Window {
    lazySizesConfig: Record<string, string | number>
  }
  interface AppUIEventMap {
    AppLoaded: {
      type: 'AppLoaded'
    }
    RouteChanged: {
      type: 'RouteChanged'
      url: string
    }
  }
}

if (!Object.fromEntries) {
  // The typings from NPM don't include the shim method
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const fe = fromEntries as any
  fe.shim()
}

if (isBrowser) {
  // eslint-disable-next-line immutable/no-mutation
  window.lazySizesConfig = window.lazySizesConfig || {}
  // eslint-disable-next-line immutable/no-mutation
  window.lazySizesConfig.expand = window.outerHeight * 5
  // eslint-disable-next-line immutable/no-mutation
  window.lazySizesConfig.expFactor = 3
}

export type TAppContext = {
  tracker: Tracker
  apiClient: ApiClient
  i18n: i18n
  t: (key: string, options?: TOptions<StringMap> | string) => string
}

export const AppContext = createContext<TAppContext | null>(null)
export function useAppContext() {
  return useContext(AppContext)!
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export default function App({ Component, pageProps, router }: AppPropsType<NextRouter, any>) {
  const tracker = useConstant(() => {
    const tracker = registerTracker()

    trackErrors(tracker)
    if (isBrowser) {
      UAParserPromise.then((UAParser) => {
        return tracker.track('user/userAgent', {
          userAgent: new UAParser.default(navigator.userAgent).getResult(),
          innerHeight: window.innerHeight,
          outerHeight: window.outerHeight,
          fontSize: Number.parseFloat(
            window.getComputedStyle(document.body, null).getPropertyValue('font-size'),
          ),
        })
      }).catch(() => {
        // noop
      })
    }

    return tracker
  })

  useEffect(() => {
    tracker.track('navigation/open')
  }, [router.asPath])

  useConstant(() => {
    const i18n = i18next.createInstance({
      lng: 'en',
      fallbackLng: 'en',
      supportedLngs: ['en', 'es', 'ru', 'tr', 'de'],
      resources: {
        en,
      },
      saveMissing: true,
    })

    i18n
      .use(initReactI18next)
      .init({
        keySeparator: '.',
        nsSeparator: ':',
      })
      .catch((error) => {
        return console.error('Error during i18next instance creation', error)
      })

    i18n.on('missingKey', (lngs, ns, key, res) => {
      const message = {
        lngs,
        ns,
        key,
        res,
      }
      console.error('missing translation key', message)
    })

    return i18n
  })

  const apiClient = useApiClient({ tracker })

  useEffect(() => {
    router.events.on('routeChangeStart', (url, { shallow }) => {
      if (shallow || url === '/') {
        return
      }
      document.getElementById('layout')?.classList.remove('fade-in')
    })
    router.events.on('routeChangeComplete', (url, { shallow }) => {
      uiEvents.next({
        type: 'RouteChanged',
        url,
      })
      if (shallow || url === '/') {
        return
      }
      document.getElementById('layout')?.classList.add('fade-in')
    })
  }, [])

  // https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked/13123626#13123626
  // https://gomakethings.com/fixing-safaris-back-button-browser-cache-issue-with-vanilla-js/
  useEffect(() => {
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        location.reload()
      }
    })
  }, [])

  const { t, i18n } = useTranslation('common')

  const context: TAppContext = {
    tracker,
    apiClient,
    t,
    i18n,
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const pageConfig = Component ? ((Component as any).config as PageConfig<unknown>) : null
  const appIsLoaded = useObservable(() => {
    return uiEvents.pipe(filterEvents(['AppLoaded']), mapTo(true))
  }, !pageConfig?.clientRenderOnly)

  const darkMode = pageProps.venueResponse?.venue.features.darkMode

  return (
    <div className={`${darkMode ? 'dark' : ''} w-full`}>
      <div className="bg-back dark:bg-backBodyDark w-full">
        <ErrorBoundary tracker={tracker}>
          <AppContext.Provider value={context}>
            <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Layout>
              <div style={{ display: appIsLoaded ? 'none' : 'block' }}>
                <AppLoader />
              </div>
              <div style={{ visibility: appIsLoaded ? 'visible' : 'hidden' }}>
                <VenueContextController
                  tracker={tracker}
                  venueResponse={pageProps.venueResponse}
                  availablePreferences={pageProps.availablePreferences}
                  translation={pageProps.translation}
                >
                  <AdminAuthController>
                    <Component {...pageProps} />
                  </AdminAuthController>
                </VenueContextController>
              </div>
            </Layout>
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    </div>
  )
}

function AdminAuthController(props: PropsWithChildren<unknown>) {
  const router = useRouter()
  const venueContext = useVenueContext()
  if (!venueContext || !router.route.startsWith('/admin/venue/[venueName]')) {
    return <>{props.children}</>
  }

  const authorizationStatus = venueContext.admin.authorizationStatus

  if (authorizationStatus.state.type === 'loading') {
    return null
  }

  if (authorizationStatus.state.type === 'wrongKey') {
    return <AdminLoginManual />
  }

  return <>{props.children}</>
}
