import { localStorage } from '@/utils/env'
import { filterEvents, useStateFromUiEvents } from '@/utils/event'
import { merge } from 'rxjs'
import { filter, mapTo } from 'rxjs/operators'
import { useVenueContext } from './venue'

export function useGdprConsent() {
  const { venue, admin } = useVenueContext()!

  const { authorizationStatus$ } = admin

  const userAgreedToCookies$ = useStateFromUiEvents({
    id: `userAgreedToCookies/${venue.name}`,
    startingValue: !venue.features.gdpr?.cookieConsentRequired,
    scope: 'global',
    storage: localStorage,
    process: (source) => {
      return merge(
        source.pipe(filterEvents(['GdprConsentAgreementClick'])),
        authorizationStatus$.pipe(
          filter((status) => {
            return status.state.type === 'ok'
          }),
        ),
      ).pipe(mapTo(true))
    },
  })

  return {
    userAgreedToCookies$,
  }
}
