import { usePersistantMap } from '@/utils/persistant-state-hooks'

export type ExcludedElementKey = {
  productGroupName: string
  productName: string
  elementName: string
}

export type ExcludedElements = {
  ingredients: Map<ExcludedElementKey, boolean>
  rootComponents: Map<ExcludedElementKey, boolean>
}

export const excludedElementKeyEncoder = {
  encode(key: ExcludedElementKey) {
    return [key.productGroupName, key.productName, key.elementName].join('/')
  },
  decode(encodedKey: string): ExcludedElementKey {
    const [productGroupName, productName, elementName] = encodedKey.split('/') as [
      string,
      string,
      string,
    ]
    return { productGroupName, productName, elementName }
  },
}

export function useExcludedElements(venueName: string) {
  const excludedIngredientsStorageKey = `excluded-ingredients/${venueName}`
  const ingredients = usePersistantMap<ExcludedElementKey, boolean>(
    excludedIngredientsStorageKey,
    excludedElementKeyEncoder,
  )

  const excludedRootComponentsStorageKey = `excluded-root-components/${venueName}`
  const rootComponents = usePersistantMap<ExcludedElementKey, boolean>(
    excludedRootComponentsStorageKey,
    excludedElementKeyEncoder,
  )

  const excludedElements: ExcludedElements = {
    ingredients,
    rootComponents,
  }

  return excludedElements
}
