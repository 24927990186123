import { entries } from './utils/array'

export type PricingTier = keyof typeof _pricingTierConfig

const _pricingTierConfig = {
  ['2021_tier_a']: {},
  ['2021_tier_d']: {},
  ['2021_tier_b']: {
    featureInherit: ['2021_tier_a'],
    featureScopes: {
      vas: {},
      menuExtended: {},
    },
  },
  ['2021_tier_c']: {
    featureInherit: ['2021_tier_b'],
    featureScopes: {
      orders: {},
    },
  },

  ['2020_menu']: {
    featureScopes: {
      menuExtended: {},
    },
  },
  ['2020_store']: {
    featureInherit: ['2020_menu'],
    featureScopes: {
      vas: {},
      orders: {},
    },
  },

  ['ru_2021_menu']: {
    featureInherit: [],
    featureScopes: {
      vas: {},
      menuExtended: {},
    },
  },
  ['ru_2021_menu_extra_venue']: {
    featureInherit: [],
    featureScopes: {
      vas: {},
      menuExtended: {},
    },
  },
  ['ru_2021_store']: {
    featureInherit: ['ru_2021_menu'],
    featureScopes: {
      vas: {},
      orders: {},
    },
  },
  ['ru_2021_store_20_percent_discount']: {
    featureInherit: ['ru_2021_menu'],
    featureScopes: {
      vas: {},
      orders: {},
    },
  },
  ['ru_2021_store_extra_venue']: {
    featureInherit: ['ru_2021_menu'],
    featureScopes: {
      vas: {},
      orders: {},
    },
  },
  ['ru_2021_pos']: {
    featureInherit: ['ru_2021_store'],
    featureScopes: {},
  },

  ['ru_2020_menu']: {
    title: 'QR-код меню',
    featureScopes: {
      vas: {},
      menuExtended: {},
    },
  },
  ['ru_2020_store']: {
    featureInherit: ['ru_2020_menu'],
    featureScopes: {
      vas: {},
      orders: {},
    },
  },
  ['ru_2020_store_discount_500']: {
    featureInherit: ['ru_2020_menu'],
    featureScopes: {
      vas: {},
      orders: {},
    },
  },
  ['ru_2020_store_extra_venue']: {
    featureInherit: ['ru_2020_menu'],
    featureScopes: {
      vas: {},
      orders: {},
    },
  },
  ['ru_2020_pos']: {
    featureInherit: ['ru_2020_store'],
    featureScopes: {},
  },
  ['ru_2023_menu']: {
    featureInherit: [],
    featureScopes: {
      vas: {},
      menuExtended: {},
    },
  },
  ['ru_2023_store_extra_venue']: {
    featureInherit: ['ru_2023_menu'],
    featureScopes: {
      vas: {},
      orders: {},
    },
  },
  ['ru_2023_2_menu']: {
    featureInherit: [],
    featureScopes: {
      vas: {},
      menuExtended: {},
    },
  },
  ['ru_2023_2_menu_extra_venue']: {
    featureInherit: [],
    featureScopes: {
      vas: {},
      menuExtended: {},
    },
  },
  ['ru_2023_store']: {
    featureInherit: ['ru_2023_2_menu'],
    featureScopes: {
      vas: {},
      orders: {},
    },
  },
  ['ru_2023_menu_extra_venue']: {
    featureInherit: [],
    featureScopes: {
      vas: {},
      menuExtended: {},
    },
  },
}

export type FeatureScopes = {
  vas?: unknown
  menuExtended?: unknown
  orders?: unknown
}

export const pricingTierConfig = _pricingTierConfig as {
  [tier in PricingTier]: {
    featureInherit?: PricingTier[]
    featureScopes?: FeatureScopes
  }
}

export function getFeatureScopes(pricingTier: PricingTier): FeatureScopes {
  const config = pricingTierConfig[pricingTier]!
  const ownFeatureScopes = config.featureScopes ?? {}
  const inheritedFeatureScopes =
    config.featureInherit?.reduce((acc, parentPricingTier) => {
      return {
        ...acc,
        ...getFeatureScopes(parentPricingTier),
      }
    }, {} as FeatureScopes) ?? {}

  return {
    ...inheritedFeatureScopes,
    ...ownFeatureScopes,
  }
}

export function getPricingTierSuccessorThatHasFeatureScope(
  currentTier: PricingTier,
  desiredFeatureScope: keyof FeatureScopes,
): PricingTier | null {
  const featureScopes = getFeatureScopes(currentTier)

  // temporarily assume we are always upgraded enough for the desired feature scope
  const forceDisableSuccessors = true
  if (featureScopes[desiredFeatureScope] || forceDisableSuccessors) {
    return currentTier
  }

  const successorTier = entries(pricingTierConfig).find(([_pricingTier, config]) => {
    return config.featureInherit?.includes(currentTier)
  })?.[0]

  if (!successorTier) {
    return null
  }

  return getPricingTierSuccessorThatHasFeatureScope(successorTier, desiredFeatureScope)
}
