import { entries } from './array'

export type Truly<T> = Exclude<T, null | undefined | false | 0 | ''>

export function truly<T>(value: T): value is Truly<typeof value> {
  return !!value
}

export function objectFilterTruly<T extends { [key in K]: V }, K extends string, V>(object: T) {
  return Object.fromEntries(
    entries(object).filter(([_key, value]) => {
      return truly(value)
    }),
  ) as unknown as {
    [key in keyof T]: Truly<V>
  }
}

export function returnOrThrow<T>(value: T | Error): T {
  if (value instanceof Error) {
    throw value
  }

  return value
}

export function tryCatch<E extends Error, R>(fn: () => R) {
  try {
    return fn()
  } catch (error) {
    return error as E
  }
}
