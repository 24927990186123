import { useCallback, useState, useRef, useEffect } from 'react'
import { YandexDeliveryScreen } from '@/components/yandex-delivery'
import { MapboxScreen } from '@/components/mapbox'
import { Venue } from 'shared/types/api'
import { MapAddress, MapScreenRef } from '@/services/maps'
import { usePersistantState } from '@/utils/persistant-state-hooks'
import { makeInputStorageKey } from '@/components/input'
import { venueGetOrderingTypes } from '@root/../shared/src/venue'
import { OrderMode } from '@/services/order'
import { useVenueContext } from '@/services/venue'
import hash from 'object-hash'

export type DeliveryMapProps = {
  onAddressSelect?(address: MapAddress | null): void
}

export function useDeliveryMap(props: { venue: Venue; orderMode: OrderMode }) {
  const { venue, orderMode } = props
  const orderingTypes = venueGetOrderingTypes(venue.features)
  const mapProvider = orderingTypes.delivery?.mapProvider
  const mapProviderType = mapProvider?.type

  const [showMapScreen, setShowMapScreen] = useState(false)
  const mapScreenRef = useRef<MapScreenRef>(null)
  const originalClickedElementRef = useRef<HTMLElement | null>(null)

  const [chosenMapAddressText, setChosenMapAddressText] = usePersistantState<string | undefined>(
    makeInputStorageKey(`delivery/street/${venue.name}`),
    undefined,
    {
      schemaVersion: 2,
    },
  )

  const [chosenMapAddressImplicit, setChosenMapAddressImplicit] = usePersistantState<
    MapAddress | undefined
  >(makeInputStorageKey(`delivery/street/${venue.name}/implicit`), undefined, {
    schemaVersion: 2,
  })

  const [chosenMapAddressEntrance, setChosenMapAddressEntrance] = usePersistantState<
    string | undefined
  >(makeInputStorageKey(`input/delivery/entrance`), undefined)

  // Wipe delivery address stored in local storage if a restaurant changed a delivery map
  useEffect(() => {
    if (mapProvider && (mapProviderType === 'yandex' || mapProviderType === 'mapbox')) {
      const mapFeaturesHash = hash(mapProvider.config.featureCollection.features)

      if (
        chosenMapAddressImplicit &&
        mapFeaturesHash !== chosenMapAddressImplicit.mapFeaturesHash
      ) {
        setChosenMapAddressText(undefined)
        setChosenMapAddressImplicit(undefined)
        setChosenMapAddressEntrance(undefined)
      }
    }
  }, [])

  const requestDeliveryAddressIfNeeded = (originalClickTarget: EventTarget) => {
    if (orderMode.value !== 'delivery') {
      return 'skipped'
    }

    if (
      !chosenMapAddressImplicit &&
      (mapProviderType === 'yandex' || mapProviderType === 'mapbox')
    ) {
      setShowMapScreen(true)
      // When a user clicks an element (order button) but hasn't selected the address yet
      // we want to save this element so we can click on it after the address is selected by the user.
      // This way we can preseve the user's flow. Otherwise the user needs to click this element
      // once again after they select the address.
      // eslint-disable-next-line immutable/no-mutation
      originalClickedElementRef.current = originalClickTarget as HTMLElement
      return 'requested'
    }

    return 'skipped'
  }

  return {
    mapProvider,
    requestDeliveryAddressIfNeeded,
    mapScreenRef,
    showMapScreen,
    setShowMapScreen,
    chosenMapAddressText,
    setChosenMapAddressText,
    chosenMapAddressImplicit,
    setChosenMapAddressImplicit,
    chosenMapAddressEntrance,
    setChosenMapAddressEntrance,
    originalClickedElementRef,
  }
}

export default function DeliveryMap(props: DeliveryMapProps) {
  const { orderManager } = useVenueContext()!
  const { deliveryMapManager } = orderManager

  const {
    showMapScreen,
    mapProvider,
    mapScreenRef,
    setShowMapScreen,
    setChosenMapAddressText,
    setChosenMapAddressImplicit,
    setChosenMapAddressEntrance,
    originalClickedElementRef,
  } = deliveryMapManager

  const layout = document.getElementById('layout')!
  // Starting from this width we want to show a map
  // in the modal window view
  const mapScreenContainerBreakpoint = 753

  const mapScreenContainerRef = useCallback((element: HTMLDivElement) => {
    if (!element) {
      return
    }

    // eslint-disable-next-line immutable/no-mutation
    element.style.width = `${layout.offsetWidth}px`
  }, [])

  useEffect(() => {
    function closeModalOnEsc(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        setShowMapScreen(false)
      }
    }
    document.addEventListener('keydown', closeModalOnEsc)

    return () => {
      document.removeEventListener('keydown', closeModalOnEsc)
    }
  }, [])

  const onAddressSelect =
    props.onAddressSelect ||
    ((address: MapAddress | null) => {
      setShowMapScreen(false)
      if (!address) {
        return
      }

      setChosenMapAddressText(address.text)
      setChosenMapAddressImplicit(address)
      address.entrance && setChosenMapAddressEntrance(address.entrance.split(' ')[1]!)

      // We want to send a user the way they came before they saw a delivery map
      // only when the function requestDeliveryAddressIfNeeded was called
      // Because when a user clicks on the address field on the main page or on the
      // checkout page it's the final destination
      originalClickedElementRef.current &&
        // There are elements that don't have click() method like path or svg.
        // We need to move up the DOM tree to find the nearest element that has click() method.
        setTimeout(() => {
          // eslint-disable-next-line immutable/no-let
          let currElement = originalClickedElementRef.current as HTMLElement
          while (true) {
            if (typeof currElement.click !== 'undefined') {
              currElement.click()
              // We are clearing this ref to prevent an error when a user clicks on the address field
              // and then follows the path that was stored in the ref before
              // eslint-disable-next-line immutable/no-mutation
              originalClickedElementRef.current = null
              break
            }
            currElement = currElement.parentNode as HTMLElement
          }
        }, 1)
    })

  return (
    <div
      ref={mapScreenContainerRef}
      className={`flex m-auto fixed w-full h-full inset-0 z-50 ${
        layout.offsetWidth >= mapScreenContainerBreakpoint
          ? 'justify-center items-center bg-primary bg-opacity-80'
          : ''
      }`}
      style={{
        visibility: showMapScreen ? 'visible' : 'hidden',
      }}
      onClick={() => {
        return setShowMapScreen(false)
      }}
    >
      <style>{`
    #layout {
      visibility: ${showMapScreen ? 'hidden' : 'initial'};
    }
    `}</style>
      <div
        className={`absolute 
          ${
            layout.offsetWidth >= mapScreenContainerBreakpoint
              ? `h-3/4 w-2/3 rounded-lg overflow-hidden`
              : 'w-full h-full'
          }`}
        id="mapContainer"
        // We don't want to fire onClick event in the predecessor when a user
        // clicks on the map
        onClick={(e) => {
          return e.stopPropagation()
        }}
      >
        {(() => {
          switch (mapProvider?.type) {
            case 'mapbox':
              return (
                <MapboxScreen
                  ref={mapScreenRef}
                  config={mapProvider.config}
                  onSelect={onAddressSelect}
                />
              )
            case 'yandex':
              return (
                <YandexDeliveryScreen
                  ref={mapScreenRef}
                  config={mapProvider.config}
                  onSelect={onAddressSelect}
                />
              )
            case 'input':
              return null
          }
        })()}
      </div>
    </div>
  )
}
