import { AdminAuthorizationStatus } from '@/components/admin'
import { injectScript } from '@/utils/dom'
import { filterEvents, uiEvents } from '@/utils/event'
import { Venue } from 'shared/types/api'
import { delay } from 'shared/utils/async'

declare global {
  interface Window {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    jivo_api?: any
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    jivo_onOpen?: any
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    jivo_onClose?: any
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    jivo_onLoadCallback?: any
  }
}

export type FreshchatParams = {
  token: string
  authorizationStatus: AdminAuthorizationStatus
  venue: Venue
}

export function makeJivosite(params: FreshchatParams) {
  const getContainer = () => {
    return document.querySelector<HTMLDivElement>('#jivo-iframe-container ~ jdiv')!
  }

  const setContainerDisplay = (displayValue: string) => {
    // eslint-disable-next-line immutable/no-mutation
    getContainer().style.display = displayValue
  }

  return {
    async initScript() {
      if (window.jivo_onLoadCallback) {
        return
      }

      // eslint-disable-next-line immutable/no-mutation
      window.jivo_onLoadCallback = async () => {
        setContainerDisplay('none')

        const authState = params.authorizationStatus.state
        if (authState.type === 'ok') {
          const adminerLink = `http://localhost:9091/?pgsql=ip17dix6wsbud71.c6x2hw29xfic.eu-central-1.rds.amazonaws.com&username=postgres&db=italy&ns=public&select=venue&where%5B0%5D%5Bcol%5D=name&where%5B0%5D%5Bop%5D=%3D&where%5B0%5D%5Bval%5D=${params.venue.name}&limit=50&text_length=100`

          window.jivo_api.setContactInfo({
            email: authState.email,
            name: params.venue.title,
            description: adminerLink,
          })

          window.jivo_api.setCustomData([
            {
              title: 'Hello',
              content: 'Open in Adminer',
              link: adminerLink,
              key: 'T',
            },
          ])
        }
      }

      // eslint-disable-next-line immutable/no-mutation
      window.jivo_onClose = async () => {
        // 400 ms was chosen imperically:
        // it's the smallest number with which the widget doesn't product a behavior
        // when re-opening the chat window leads to a closed state
        await delay(400)
        setContainerDisplay('none')
      }

      await injectScript(`https://code-ya.jivosite.com/widget/${params.token}`)
      uiEvents.pipe(filterEvents(['JivositeButtonClick', 'RequestSupportWidget'])).subscribe(() => {
        setContainerDisplay('block')
        window.jivo_api.open()
      })
    },
  }
}
//deploy
