import { Product } from './types/api'

export const allowedProductImageMimeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp']

export function getImageFileType(file: File) {
  return file.name.endsWith('webp') ? 'image/webp' : file.type
}

export function isAllowedImageType(file: File) {
  return allowedProductImageMimeTypes.includes(getImageFileType(file))
}

export function isProductExcludedFromOrderPriceModification(
  product: Product,
  excludedProducts: string[],
) {
  return (
    excludedProducts.includes(`${product.productGroupName}/${product.name}`) ||
    excludedProducts.includes(product.productGroupName)
  )
}
