import { isBrowser } from '@/utils/env'

export function injectScript(src: string, scriptAttributes?: Partial<HTMLScriptElement>) {
  if (!isBrowser) {
    return Promise.resolve()
  }

  const attributes: Partial<HTMLScriptElement> = {
    async: true,
    src,
    ...scriptAttributes,
  }
  const scriptElement = document.createElement('script')
  Object.assign(scriptElement, attributes)

  // eslint-disable-next-line immutable/no-mutation
  scriptElement.async = true
  // eslint-disable-next-line immutable/no-mutation
  scriptElement.src = src
  const firstScriptOnPage = document.getElementsByTagName('script')[0]
  firstScriptOnPage!.parentNode!.insertBefore(scriptElement, firstScriptOnPage!)

  return new Promise<void>((resolve) => {
    scriptElement.addEventListener('load', () => {
      resolve()
    })
  })
}

export function fileToDataUrl(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    // eslint-disable-next-line immutable/no-mutation
    reader.onload = (e) => {
      resolve(e.target!.result! as string)
    }
    reader.readAsDataURL(file)
  })
}

export function fileToText(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    // eslint-disable-next-line immutable/no-mutation
    reader.onload = (e) => {
      resolve(e.target!.result! as string)
    }
    reader.readAsText(file)
  })
}
