import { useEffect, useState } from 'react'
import { useAppContext } from '@/pages/_app'
import { filterEvents, uiEvents, useDefaultObservable, useStateFromUiEvents } from '@/utils/event'
import { localStorage, sessionStorage, stubStorage } from '@/utils/env'
import { map, tap, withLatestFrom } from 'rxjs/operators'
import { BillingSubscription, BillingPrices } from 'shared/types/api'
import { getFeatureScopes } from 'shared/pricing-tiers'
import { useRouter } from 'next/router'

declare global {
  interface AppUIEventMap {
    AdminAuthorizationStatusChange: {
      type: 'AdminAuthorizationStatusChange'
      newStatus: AdminAuthorizationStatus
    }
  }
}

export type AdminAuthorizationStatus = {
  state: AdminAuthorizationState
}

export type AdminAuthorizationStateLoading = {
  type: 'loading'
}
export type AdminAuthorizationStateUnauthorized = {
  type: 'unauthorized'
}
export type AdminAuthorizationStateWrongKey = {
  type: 'wrongKey'
}
export type AdminAuthorizationStateOk = {
  type: 'ok'
  viewMode: 'admin' | 'customer'
  email: string
  role: string
  subscription: BillingSubscription
  prices: BillingPrices
}
export type AdminAuthorizationState =
  | AdminAuthorizationStateLoading
  | AdminAuthorizationStateUnauthorized
  | AdminAuthorizationStateWrongKey
  | AdminAuthorizationStateOk

export type AdminParams = {
  venueName: string
}
export function useAdmin(params: AdminParams) {
  const { apiClient } = useAppContext()

  const adminViewMode$ = useStateFromUiEvents<AdminAuthorizationStateOk['viewMode'] | null>({
    id: 'adminViewMode',
    storage: localStorage,
    startingValue: null,
    process: (source) => {
      return source.pipe(
        filterEvents(['ViewAsCustomerClick', 'ViewAsAdminClick']),
        tap(() => {
          // eslint-disable-next-line immutable/no-mutation
          sessionStorage.removeItem('tabOpenedWithAdminUrl')
        }),
        map((event) => {
          switch (event.type) {
            case 'ViewAsCustomerClick':
              return 'customer'
            case 'ViewAsAdminClick':
              return 'admin'
          }
        }),
      )
    },
  })

  const authorizationStatus$ = useStateFromUiEvents({
    id: 'adminAuthorizationStatus',
    storage: stubStorage,
    startingValue: { state: { type: 'loading' } } as AdminAuthorizationStatus,
    process: (source, scan) => {
      return source.pipe(
        scan((status, event) => {
          switch (event.type) {
            case 'AdminAuthorizationStatusChange':
              return event.newStatus
            default:
              return status
          }
        }),
        withLatestFrom(adminViewMode$),
        map(([status, viewMode]) => {
          if (status.state.type !== 'ok') {
            return status
          }

          const viewModeFromUrl = sessionStorage.getItem('tabOpenedWithAdminUrl') ? 'admin' : null

          return {
            state: {
              ...status.state,
              viewMode: viewModeFromUrl ?? viewMode ?? status.state.viewMode,
            },
          }
        }),
      )
    },
  })

  const authorizationStatus = useDefaultObservable(authorizationStatus$)

  const [runtimeVenueVersion, setRuntimeVenueVersion] = useState(() => {
    return Math.random()
  })

  const router = useRouter()
  useEffect(() => {
    const url = new URL(location.href)
    const apiKey = url.searchParams.get('a')

    const forceAdminView = url.searchParams.has('fa')
    if (apiKey || forceAdminView) {
      sessionStorage.setItem('tabOpenedWithAdminUrl', '1')
      console.log('rewriting url before', url.toString())
      url.searchParams.delete('a')
      url.searchParams.delete('fa')
      console.log('rewriting url', url.toString())

      router.replace(url.toString(), url.toString(), { shallow: true })
    }

    const fetchAuth = async () => {
      const fetchParams = apiKey
        ? {
            method: 'POST',
            body: JSON.stringify({
              apiKey,
            }),
          }
        : {
            method: 'GET',
          }

      const response = await apiClient.fetch(`/admin/venue/${params.venueName}/auth`, fetchParams)
      if (response instanceof Error) {
        return
      }

      const body = await response.json()
      uiEvents.next({
        type: 'AdminAuthorizationStatusChange',
        newStatus: ((): AdminAuthorizationStatus => {
          switch (response.status) {
            case 200:
              return {
                state: {
                  type: 'ok',
                  email: body.email,
                  viewMode: 'customer',
                  role: body.role,
                  subscription: body.subscription,
                  prices: body.prices,
                },
              }
            case 401:
              return { state: { type: 'wrongKey' } }
            default:
              return { state: { type: 'unauthorized' } }
          }
        })(),
      })
      return response
    }

    fetchAuth().catch((e) => {
      return console.log('auth', e)
    })
  }, [runtimeVenueVersion])

  const isAdmin = authorizationStatus.state.type === 'ok'
  const isAdminView =
    authorizationStatus.state.type === 'ok' && authorizationStatus.state.viewMode === 'admin'

  const tierCode =
    authorizationStatus.state.type === 'ok'
      ? authorizationStatus.state.subscription?.tierCode
      : null
  const featureScope = tierCode ? getFeatureScopes(tierCode) : {}

  return {
    authorizationStatus,
    authorizationStatus$,
    isAdmin,
    isAdminView,
    featureScope,
    refreshAdmin: () => {
      setRuntimeVenueVersion(Math.random())
    },
  }
}
