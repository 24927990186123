export type SpinnerProps = {
  className?: string
  color?: string
}
export function Spinner(props: SpinnerProps) {
  const color = props.color || 'stroke-primary dark:stroke-primaryDark'
  return (
    <svg
      className={`${props.className ?? ''} spinner w-full`}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <style jsx global>{`
        @-webkit-keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        svg.spinner {
          animation: spinner 1s infinite linear;
        }
      `}</style>
      <circle
        cx="50"
        cy="50"
        r="40"
        strokeWidth="9"
        strokeDasharray="62.83185307179586 62.83185307179586"
        fill="none"
        className={color}
        strokeLinecap="round"
      ></circle>
    </svg>
  )
}
