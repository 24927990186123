import {
  usePersistantState,
  usePersistantCollection,
  Dict,
  getStorage,
} from '@/utils/persistant-state-hooks'
import {
  Venue,
  OrderPrices,
  OrderGetPriceRequest,
  OrderModeValue,
  NewOrderProductModifiers,
} from 'shared/types/api'
import { useEffect } from 'react'
import { sum, values } from 'shared/utils/array'
import { useAppContext } from '@/pages/_app'
import { useConstant } from '@/utils/use-constant'
import { makeInputStorageKey } from '@/components/input'
import { INPUT_PHONE_KEY, INPUT_PHONE_STORAGE } from '@/components/input-phone-storage'
import { useDeliveryMap } from '@/components/delivery-map'

export type Basket = {
  size: number
  products: ReturnType<typeof useBasketProducts>
  prices: BasketOrderPrices
}
export type BasketOrderPrices = OrderPrices | 'loading'
export function useBasket(
  venue: Venue,
  orderModeValue: OrderModeValue,
  orderModeSource: 'initial' | 'client',
  deliveryMapManager: ReturnType<typeof useDeliveryMap>,
): Basket {
  const venueName = venue.name
  const [prices, setPrices] = useBasketPrices(orderModeValue, venueName)
  const { apiClient } = useAppContext()

  const activeRequests = useConstant(() => {
    return {
      count: 0,
    }
  })

  const { chosenMapAddressImplicit } = deliveryMapManager

  const deliveryZoneId = chosenMapAddressImplicit?.deliveryZoneId as string

  const updateBasketPrices = async (basketProducts: BasketProduct[]) => {
    const phoneNumber = getStorage(
      makeInputStorageKey(`${INPUT_PHONE_KEY}/implicit`),
      INPUT_PHONE_STORAGE,
    ).get() as string

    activeRequests.count++

    setPrices('loading')

    const requestBody: OrderGetPriceRequest = {
      products: basketProducts,
      orderMode: orderModeValue,
      phoneNumber,
      deliveryZoneId,
    }

    const response = await apiClient.fetch(`/venue/${venueName}/order/get-price`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })

    activeRequests.count--
    if (activeRequests.count > 0) {
      setPrices('loading')
      return
    }

    if (response instanceof Error) {
      throw response
    }

    const prices = (await response.json()) as OrderPrices
    setPrices(prices)
  }

  const basketProducts = useBasketProducts(
    orderModeValue,
    venueName,
    venue,
    async (basketProducts) => {
      setPrices('loading')
      updateBasketPrices(values(basketProducts))
    },
  )

  const size = sum(
    basketProducts.getAll().map((orderedProduct) => {
      const isUsingVolumeUnitsForQuantity = !!venue?.productGroups
        .find((pg) => {
          return pg.name === orderedProduct.productGroupName
        })
        ?.products.find((p) => {
          return p.name === orderedProduct.productName
        })?.isUsingVolumeUnitsForQuantity

      return isUsingVolumeUnitsForQuantity
        ? orderedProduct.quantity > 0
          ? 1
          : 0
        : orderedProduct.quantity
    }),
  )

  useEffect(() => {
    if (size === 0 || orderModeSource !== 'client') {
      return
    }
    updateBasketPrices(basketProducts.getAll())
  }, [orderModeSource, deliveryZoneId])

  return {
    size,
    products: basketProducts,
    prices,
  }
}

export type BasketProduct = {
  productName: string
  productGroupName: string
  quantity: number
  modifiers: NewOrderProductModifiers
  createdAt: number
}

function useBasketProducts(
  orderModeValue: OrderModeValue,
  venueName: string,
  venue: Venue | null,
  onChange?: (basketProducts: Dict<BasketProduct>) => void,
) {
  const basketStorageKey = basketOrderModeKey(orderModeValue, venueName)

  const basketCollection = usePersistantCollection<BasketProduct>(basketStorageKey, {
    schemaVersion: 4,
    onStateChange: (_update, state) => {
      onChange && onChange(state)
    },
  })

  useEffect(() => {
    if (!venue) {
      return
    }
    basketCollection.deleteWhen((basketItem) => {
      const productGroup = venue.productGroups.find((pg) => {
        return pg.name === basketItem.productGroupName
      })
      const product = productGroup?.products.find((p) => {
        return p.name === basketItem.productName
      })

      return (
        !product ||
        product.isInStopList ||
        product.modifierGroups.some((modifierGroup) => {
          return (
            // group is required, but every option is in the stop list
            (modifierGroup.isRequired &&
              modifierGroup.options.every((modifierOption) => {
                return modifierOption.isInStopList
              })) ||
            // there's a selected option that was put to the stop list
            modifierGroup.options.some((modifierOption) => {
              return (
                modifierOption.isInStopList &&
                basketItem.modifiers[modifierGroup.name]?.[modifierOption.name]
              )
            })
          )
        })
      )
    })
  }, [venue])

  const setQuantity = (
    product: Omit<BasketProduct, 'quantity' | 'createdAt'>,
    quantity: number | ((currentQuantity: number) => number),
  ) => {
    const basketProduct = basketCollection.filter((p) => {
      return (
        p.productGroupName === product.productGroupName &&
        p.productName === product.productName &&
        JSON.stringify(p.modifiers) === JSON.stringify(product.modifiers)
      )
    })[0]

    const currentQuantity = basketProduct?.quantity || 0
    const newQuantity = typeof quantity === 'number' ? quantity : quantity(currentQuantity)

    if (basketProduct) {
      return basketCollection.set(basketProduct.__id, {
        quantity: newQuantity,
      })
    }

    return basketCollection.insert({
      ...product,
      quantity: newQuantity,
      createdAt: Date.now(),
    })
  }

  const insert = (product: Omit<BasketProduct, 'createdAt'>) => {
    return basketCollection.insert({
      ...product,
      createdAt: Date.now(),
    })
  }

  return { ...basketCollection, setQuantity, insert }
}

function basketOrderModeKey(orderModeValue: OrderModeValue, venueName: string) {
  return `basket/${orderModeValue}/${venueName}`
}

function useBasketPrices(orderModeValue: OrderModeValue, venueName: string) {
  const basketPricesStorageKey = basketPricesKey(orderModeValue, venueName)

  return usePersistantState<BasketOrderPrices>(basketPricesStorageKey, 'loading')
}

function basketPricesKey(orderModeValue: OrderModeValue, venueName: string) {
  return `basket-prices/${orderModeValue}/${venueName}`
}
