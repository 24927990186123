import setMinutes from 'date-fns/setMinutes'
import setHours from 'date-fns/setHours'
import addDays from 'date-fns/addDays'
import addMinutes from 'date-fns/addMinutes'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes'
import { flow, range } from 'lodash'
import { returnOrThrow } from 'shared/utils/types'

export type Time = {
  hours: number
  minutes: number
}

export function parseTime(timeString: string): Time | Error {
  const [hours, minutes] = timeString.split(':').map((s) => {
    return s === undefined ? s : Number(s)
  })
  if (hours === undefined || minutes === undefined) {
    return new Error(`bad time formatting: ${timeString}`)
  }

  return { hours, minutes }
}

export const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as const

export const translatedDaysOfWeek = {
  mon: 'date:monday',
  tue: 'date:tuesday',
  wed: 'date:wednesday',
  thu: 'date:thursday',
  fri: 'date:friday',
  sat: 'date:saturday',
  sun: 'date:sunday',
} as const

export function createIntervalFromTime(startTime: Time, endTime: Time, date: Date) {
  const endOverMidnight = timeToNumber(startTime) >= timeToNumber(endTime)

  const start = setMinutes(setHours(date, startTime.hours), startTime.minutes)
  const end = addDays(
    setMinutes(setHours(date, endTime.hours), endTime.minutes),
    endOverMidnight ? 1 : 0,
  )

  return { start, end }
}

export function timeToNumber(time: Time) {
  return time.hours * 60 + time.minutes
}

export function generateTimeOptions(from: Time, to: Time, stepMinutes: number) {
  const now = new Date()
  const rangeStart = roundToNearestMinutes(setMinutes(setHours(now, from.hours), from.minutes))
  const rangeEndUnadjasted = setMinutes(setHours(rangeStart, to.hours), to.minutes)
  const rangeEnd =
    rangeEndUnadjasted > rangeStart ? rangeEndUnadjasted : addDays(rangeEndUnadjasted, 1)
  const minutesTillClosing = differenceInMinutes(rangeEnd, rangeStart)
  const totalSteps = Math.ceil(minutesTillClosing / 15)

  return range(0, totalSteps)
    .map((v) => {
      const rounded = roundToNearestMinutes(addMinutes(rangeStart, v * stepMinutes), {
        nearestTo: stepMinutes,
      })

      return rounded
    })
    .filter((v) => {
      return v < rangeEnd
    })
}

export function timeToLocaleString(time: Time) {
  return flow(
    () => {
      return new Date()
    },
    (now) => {
      return setHours(now, time.hours)
    },
    (now) => {
      return setMinutes(now, time.minutes)
    },
    (date) => {
      return new Intl.DateTimeFormat('default', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(date)
    },
  )()
}

export function localizeTimeString(timeString: string) {
  return flow(parseTime, returnOrThrow, timeToLocaleString)(timeString)
}
