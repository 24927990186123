/* eslint-disable immutable/no-mutation, immutable/no-this */
// There is no way to implement and error boundary with functional components

import React, { ErrorInfo, PropsWithChildren } from 'react'
import { Tracker } from '@/services/tracking'
import { isBrowser } from '@/utils/env'

export type ErrorBoundaryProps = PropsWithChildren<{
  tracker: Tracker
}>
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  private tracker: Tracker
  state: { hasError: boolean }
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.tracker = props.tracker
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: isBrowser }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('error in componentDidCatch', error)
    this.tracker.trackError(error, {
      isErrorBoundary: true,
      componentStack: errorInfo.componentStack,
    })
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}
