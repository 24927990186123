import {
  CropBox,
  Product,
  ProductPicture,
  ProductUpdateBody,
  SaveProductOrderBody,
} from 'shared/types/api'
import { allowedProductImageMimeTypes, getImageFileType } from 'shared/product'
import { ApiClient } from '@/utils/fetch'

export const placeholderImage: ProductPicture = {
  url: '/img/shared/product-placeholder-image.png',
}

export function getProductMainPicture(product: Product) {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const mainPicture = product.pictures?.[0] ?? (product as any).media?.[0] ?? null

  if (!mainPicture) {
    return null
  }

  const url = mainPicture.url.replace(
    'https://foodeon.com/assets/',
    'https://static.foodeon.com/assets/',
  )

  return {
    ...mainPicture,
    url,
  }
}

export async function uploadPresignedFile(
  venueName: string,
  baseName: string,
  file: File,
  apiClient: ApiClient,
) {
  const fileType = getImageFileType(file)

  const extension = file.name.split('.').slice(-1)[0]!
  const fileName = `${baseName}.${extension}`
  const uploadUrlResponse = await apiClient.fetch(
    `/admin/venue/${venueName}/get-presigned-url?name=${fileName}&content-type=${fileType}`,
  )
  if (uploadUrlResponse instanceof Error) {
    return uploadUrlResponse
  }
  const { url: uploadUrl, key } = await uploadUrlResponse.json()

  if (!allowedProductImageMimeTypes.includes(fileType)) {
    return
  }

  const Compressor = (await import('compressorjs')).default
  const blob = await new Promise<Blob>((resolve, reject) => {
    new Compressor(file, {
      success: resolve,
      error: reject,
      quality: 0.8,
    })
  })

  const uploadResponse = await apiClient.fetch(uploadUrl, {
    method: 'PUT',
    body: blob,
    headers: {
      'x-amz-acl': 'public-read',
      'content-type': fileType,
    },
    credentials: 'omit',
  })

  if (uploadResponse instanceof Error) {
    return uploadResponse
  }

  return `https://foodeon.com/${key}`
}

export async function uploadMainImage(
  venueName: string,
  product: Product,
  file: File,
  cropBox: CropBox,
  apiClient: ApiClient,
) {
  const baseName = `${venueName}-${product.productGroupName}-${product.name}`
  const photoUrl = await uploadPresignedFile(venueName, baseName, file, apiClient)
  if (photoUrl instanceof Error || !photoUrl) {
    return photoUrl
  }
  const body: ProductUpdateBody = {
    mainPhoto: {
      url: photoUrl,
      cropBox,
    },
  }

  return updateProduct(apiClient, venueName, product, body)
}

export function updateProduct(
  apiClient: ApiClient,
  venueName: string,
  product: Product,
  newValues: ProductUpdateBody,
) {
  return apiClient.fetch(
    `/admin/venue/${venueName}/product/${product.productGroupName}/${product.name}`,
    {
      method: 'POST',
      body: JSON.stringify(newValues),
    },
  )
}

export function archiveProduct(apiClient: ApiClient, venueName: string, product: Product) {
  return apiClient.fetch(
    `/admin/venue/${venueName}/product/${product.productGroupName}/${product.name}`,
    {
      method: 'DELETE',
    },
  )
}

export function saveProductOrder(
  apiClient: ApiClient,
  venueName: string,
  productGroupName: string,
  productNames: string[],
) {
  const body: SaveProductOrderBody = {
    productNames,
  }

  return apiClient.fetch(`/admin/venue/${venueName}/product/${productGroupName}/order`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}
