import { AdminAuthorizationStatus } from '@/components/admin'
import { injectScript } from '@/utils/dom'
import { filterEvents, uiEvents } from '@/utils/event'
import { Venue } from 'shared/types/api'

declare global {
  interface Window {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    fcWidget?: any
  }
}

export type FreshchatParams = {
  token: string
  authorizationStatus: AdminAuthorizationStatus
  venue: Venue
}

export function makeFreshchat(params: FreshchatParams) {
  return {
    async initScript() {
      await injectScript('https://wchat.freshchat.com/js/widget.js', {
        id: 'freshchat-js-sdk',
      })
      const fcWidget = window.fcWidget
      if (!fcWidget) {
        console.error('no fcWidget after injecting the script')
      }
      const authState = params.authorizationStatus.state

      fcWidget.init({
        token: params.token,
        host: 'https://wchat.eu.freshchat.com',
        externalId: `venue-${params.venue.name}`,
        firstName: params.venue.title,
        email: authState.type === 'ok' ? authState.email : undefined,
      })

      uiEvents
        .pipe(filterEvents(['FreshChatButtonClick', 'RequestSupportWidget']))
        .subscribe(() => {
          fcWidget.open()
        })
    },
  }
}
