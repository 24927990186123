import { filterEvents, uiEvents } from '@/utils/event'
import { values } from 'shared/utils/array'
import { createContext, PropsWithChildren, RefObject, useContext, useEffect, useRef } from 'react'
import { filter } from 'rxjs'
import scrollTo from 'animated-scroll-to'

declare global {
  interface AppUIEventMap {
    FormSubmit: {
      type: 'FormSubmit'
      id: string
      isValid: boolean
    }
  }
}

export type TFormContext = {
  formId: string
  registerFormElement: (id: string, formElement: FormElement) => void
  unregisterFormElement: (id: string) => void
}
export const FormContext = createContext<TFormContext | null>(null)

export function useForm(params: { id: string }) {
  const submit$ = uiEvents.pipe(
    filterEvents(['FormSubmit']),
    filter((event) => {
      return event.id === params.id
    }),
  )

  return {
    id: params.id,
    submit$,
  }
}

export function useFormContext(params: {
  props: {
    id: string
    inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>
    actions: {
      touch: () => { isValid: boolean }
    }
    valid: boolean
  }
}) {
  const formContext = useContext(FormContext)
  useEffect(() => {
    formContext?.registerFormElement(params.props.id, {
      ref: params.props.inputRef,
      touch: () => {
        return params.props.actions.touch()
      },
    })

    return () => {
      formContext?.unregisterFormElement(params.props.id)
    }
  }, [params.props])
}

export type FormElement = {
  ref?: RefObject<HTMLInputElement | HTMLTextAreaElement>
  touch: () => {
    isValid: boolean
  }
}

export type FormProps = PropsWithChildren<{
  id: string
  className?: string
}>

export function Form(props: FormProps) {
  const formInputsRef = useRef<{ [id: string]: FormElement }>({})

  return (
    <form
      className={props.className}
      onSubmit={(e) => {
        console.log('submit')
        e.preventDefault()

        const firstInvalidElement = values(formInputsRef.current).find((ref) => {
          return !ref.touch().isValid
        })

        const firstInvalidElementDom = firstInvalidElement?.ref?.current

        if (firstInvalidElementDom) {
          const form = e.target as HTMLFormElement
          const findFirstScrollableParent = (element: HTMLElement): HTMLElement | Window => {
            if (!element.parentElement) {
              return window
            }

            if (element.scrollHeight > element.clientHeight) {
              return element.parentElement
            }

            return findFirstScrollableParent(element.parentElement)
          }

          const scrollableParent = findFirstScrollableParent(form)

          setTimeout(() => {
            scrollTo(firstInvalidElementDom, {
              verticalOffset: -100,
              elementToScroll: scrollableParent,
            })
          }, 1)
        }

        uiEvents.next({
          id: props.id,
          type: 'FormSubmit',
          isValid: !firstInvalidElement,
        })
      }}
    >
      <FormContext.Provider
        value={{
          formId: props.id,
          registerFormElement: (id: string, formElement: FormElement) => {
            // eslint-disable-next-line immutable/no-mutation
            formInputsRef.current[id] = formElement
          },
          unregisterFormElement(id: string) {
            delete formInputsRef.current[id]
          },
        }}
      >
        {props.children}
      </FormContext.Provider>
    </form>
  )
}
