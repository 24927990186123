import { Observable } from 'rxjs'

declare global {
  interface Window {
    ym?: (a: string, b: string, c: unknown) => void
  }
}

export const YA_METRIKA_ID = '62815231'

export function makeYandexMetrika(routeChanges$: Observable<void>) {
  // eslint-disable-next-line immutable/no-let
  let inited = false
  const registeredTrackers: Record<string, unknown> = {}

  const trackPageView = (id: string) => {
    if (!window.ym) {
      return
    }
    window.ym(id, 'hit', location.href)
  }

  routeChanges$.subscribe(() => {
    for (const id in registeredTrackers) {
      trackPageView(id)
    }
  })

  return {
    initScript() {
      if (inited) {
        return
      }
      inited = true
      new Function(`
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://cdn.jsdelivr.net/npm/yandex-metrica-watch/tag.js", "ym");
        window.ym = ym;
      `)()
    },

    registerTrackingId(id: string, config: Record<string, unknown> = {}) {
      if (!window.ym) {
        return
      }

      // eslint-disable-next-line immutable/no-mutation
      registeredTrackers[id] = config
      window.ym(id, 'init', config)
      trackPageView(id)
    },
  }
}
