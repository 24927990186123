import { BehaviorSubject, Observable, OperatorFunction, Subject } from 'rxjs'
import { filter, distinctUntilChanged, tap, map, shareReplay, startWith } from 'rxjs/operators'
import { useObservable } from 'rxjs-hooks'
import { DependencyList, useEffect, useRef, useState } from 'react'
import { getStorage } from './persistant-state-hooks'
import { isBrowser, isDevBrowser, stubStorage } from './env'
import { useConstant } from '@/utils/use-constant'
import isEqual from 'lodash.isequal'

declare global {
  interface AppUIEventMap {
    dummy: {
      type: 'dummy'
    }
  }
}

export type AppUIEvent = AppUIEventMap[keyof AppUIEventMap]
export type AppUIEventType = AppUIEvent['type']

export const uiEvents = new Subject<AppUIEvent>()

if (isDevBrowser) {
  uiEvents.subscribe((event) => {
    if (localStorage.getItem('DEV_TRACK')) {
      console.log('UI Event', event.type, event)
    }
  })
}

export function filterEvents<T extends AppUIEventType>(types: T[]) {
  return function filterEventsOperator(
    source: Observable<AppUIEvent>,
  ): Observable<AppUIEventMap[T]> {
    return source.pipe(
      filter((e): e is AppUIEventMap[T] => {
        return !!types.find((t) => {
          return t === e.type
        })
      }),
    )
  }
}

export type DefaultObservable<T> = Observable<T> & {
  defaultValue: T
}

export function makeDefaultObservable<T>(
  defaultValue: T,
  observable: Observable<T>,
): DefaultObservable<T> {
  return Object.create(observable, {
    defaultValue: {
      value: defaultValue,
    },
  })
}

export function useDefaultObservable<T>(defaultObservable: DefaultObservable<T>) {
  return useObservable(() => {
    return defaultObservable
  }, defaultObservable.defaultValue)
}

export function useDefaultObservableAsRef<T>(defaultObservable: DefaultObservable<T>) {
  const ref = useRef(defaultObservable.defaultValue)

  useSubscription(defaultObservable, (newValue) => {
    // eslint-disable-next-line immutable/no-mutation
    ref.current = newValue
  })

  return ref
}

export function useObservableAsRef<T>(observable: Observable<T>) {
  const ref = useRef<T | null>(null)

  useSubscription(observable, (newValue) => {
    // eslint-disable-next-line immutable/no-mutation
    ref.current = newValue
  })

  return ref
}

const observableStateCache = new Map<string, unknown>()

export type ObservableStateParamsSource<S> = {
  source: Observable<S>
}

export type ObservableStateParams<T, S> = {
  id: string
  startingValue: T
  storage?: Storage
  scope?: 'global' | 'local'
} & (
  | {
      process: (
        s: Observable<S>,
        scan: (accumulator: (acc: T, value: S, index: number) => T) => OperatorFunction<S, T>,
      ) => Observable<T>
    }
  | {
      scan: (acc: T, value: S, index: number) => T
    }
)

export function makeObservableState<T, S>(
  params: ObservableStateParams<T, S> & ObservableStateParamsSource<S>,
) {
  const cachedValue = observableStateCache.get(params.id)
  if (cachedValue) {
    return cachedValue as DefaultObservable<T>
  }
  const storageKey = makeUiStateKey(params.id)
  const storage = getStorage<T>(storageKey, params.storage ?? stubStorage)
  const startingValue = params.startingValue

  // eslint-disable-next-line immutable/no-let
  let state = startingValue

  const scanOperator = (fn: (acc: T, cur: S, index: number) => T) => {
    return (obs: Observable<S>) => {
      return obs.pipe(
        map((value, index) => {
          return fn(state, value, index)
        }),
      )
    }
  }

  const browserValue = isBrowser ? storage.get() : undefined

  const sourceProcessed$ =
    'process' in params
      ? params.process(params.source, scanOperator)
      : params.source.pipe(scanOperator(params.scan))

  const sourceResult$: Observable<T> = sourceProcessed$.pipe(
    startWith(browserValue ?? startingValue),
    distinctUntilChanged<T>(isEqual),
    tap((newValue) => {
      const cachedValue = observableStateCache.get(params.id) as DefaultObservable<T> | undefined
      if (cachedValue) {
        // Use case: if an observable is shared between pages and cached,
        // not updating defaultValue leads to a stale starting state on the new page
        observableStateCache.set(params.id, makeDefaultObservable(newValue, cachedValue))
      }
      state = newValue
      storage.update(newValue)
    }),
    shareReplay(1),
  )

  const observable = makeDefaultObservable(startingValue, sourceResult$)
  observableStateCache.set(params.id, observable)
  return observable
}

export function useObservableState<T, S = T>(
  params: ObservableStateParams<T, S> & ObservableStateParamsSource<S>,
) {
  const resultingObservable = useConstant(() => {
    return makeObservableState(params)
  })

  useSubscription(resultingObservable, () => {
    // noop
  })

  useEffect(() => {
    switch (params.scope ?? 'local') {
      case 'global':
        return
      case 'local':
        return () => {
          observableStateCache.delete(params.id)
        }
    }
  }, [])

  return resultingObservable
}

export function useSubscription<T>(observable: Observable<T>, next: (value: T) => void) {
  useEffect(() => {
    const subscription = observable.subscribe(next)
    return () => {
      subscription.unsubscribe()
    }
  }, [])
}

export function useSubject<T>() {
  const subject = useConstant(() => {
    return new Subject<T>()
  })

  useEffect(() => {
    return () => {
      return subject.complete()
    }
  }, [])

  return subject
}

export function useBehaviorSubject<T>(defaultValue: T) {
  const subject = useConstant(() => {
    return new BehaviorSubject<T>(defaultValue)
  })

  useEffect(() => {
    return () => {
      return subject.complete()
    }
  }, [])

  return subject
}

export function useEffect$<Deps extends DependencyList>(deps: readonly [...Deps]) {
  const subject = useSubject<readonly [...Deps]>()

  useEffect(() => {
    subject.next(deps)
  }, deps)

  return subject.asObservable()
}

export function useStateFromUiEvents<T>(
  params: ObservableStateParams<T, AppUIEvent>,
  returnType?: 'observable',
): DefaultObservable<T>
export function useStateFromUiEvents<T>(
  params: ObservableStateParams<T, AppUIEvent>,
  returnType: 'state',
): T
export function useStateFromUiEvents<T>(
  params: ObservableStateParams<T, AppUIEvent>,
  returnType: 'state' | 'observable' = 'observable',
) {
  const observable = useObservableState({
    ...params,
    source: uiEvents,
  })
  const state = useDefaultObservable(observable)
  return returnType === 'observable' ? observable : state
}

function makeUiStateKey(id: string) {
  return `uiState/${id}`
}

export function useClientValue<T>(initialValue: T, clientValue: T) {
  const [initialValueState, setInitialValueState] = useState<T | null>(initialValue)

  useEffect(() => {
    setInitialValueState(null)
  }, [])

  if (initialValueState !== null) {
    return {
      type: 'initial',
      value: initialValueState,
    } as const
  }

  return {
    type: 'client',
    value: clientValue,
  } as const
}

export function makeEmmiterFor<T>(subject: Subject<T>) {
  return (value: T) => {
    subject.next(value)
  }
}
