import { useAppContext } from '@/pages/_app'
import { useVenueContext } from '@/services/venue'
import { uiEvents } from '@/utils/event'

declare global {
  interface AppUIEventMap {
    FreshChatButtonClick: {
      type: 'FreshChatButtonClick'
    }
  }
}

export function FreshChatButton() {
  const { t } = useAppContext()
  const { admin } = useVenueContext()!
  if (admin.authorizationStatus.state.type !== 'ok') {
    return null
  }
  return (
    <button
      className="bg-help text-back dark:text-backDark p-2 w-full flex justify-center items-center rounded-lg leading-tight"
      onClick={() => {
        uiEvents.next({
          type: 'FreshChatButtonClick',
        })
      }}
    >
      <div>{t('sidebar:chatWithUs')}</div>
      <div className="ml-3">
        <FreshChatIcon />
      </div>
    </button>
  )
}

export function FreshChatStyles() {
  return (
    <style>{`
    #fc_frame {
      display: none;
    }
    #fc_frame.fc-open {
      display: block;
    }
  `}</style>
  )
}

function FreshChatIcon() {
  return (
    <svg fill="none" width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6843 23.0714H16.7695C16.1806 23.0714 15.7036 23.5351 15.7036 24.1076C15.7036 24.6801 16.1806 25.1439 16.7695 25.1439H24.6843C25.2732 25.1439 25.7502 24.6801 25.7502 24.1076C25.7502 23.5351 25.2732 23.0714 24.6843 23.0714Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.5559 18H16.7695C16.1806 18 15.7036 18.4638 15.7036 19.0363C15.7036 19.6088 16.1806 20.0725 16.7695 20.0725H29.5559C30.1448 20.0725 30.6218 19.6088 30.6218 19.0363C30.6218 18.4638 30.1448 18 29.5559 18Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.8189 24.1971C34.8189 27.2622 32.3157 29.7466 29.2273 29.7466H13.6938C13.0077 29.7466 12.4514 29.1945 12.4514 28.5135V19.0695C12.4514 16.0057 14.9546 13.52 18.0429 13.52H29.2273C32.3157 13.52 34.8189 16.0057 34.8189 19.0695V24.1971ZM39.1431 0.359863H22.3671C10.0138 0.359863 -0.000366211 10.2987 -0.000366211 22.5591C-0.000366211 34.8195 10.0138 44.7583 22.3671 44.7583C34.7205 44.7583 44.7346 34.8195 44.7346 22.5591V5.90934C44.7346 2.84424 42.2314 0.359863 39.1431 0.359863Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
