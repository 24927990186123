export function unique<T, K>(array: T[], keyFn?: (v: T) => K): T[] {
  const map = new Map<K | T, T>()
  for (const item of array) {
    const key = keyFn ? keyFn(item) : item
    map.set(key, item)
  }

  return Array.from(map.values())
}

export function join<T>(array: T[], separator: T | ((idx: number) => T)): T[] {
  return array.reduce((acc, cur, idx) => {
    const s = separator instanceof Function ? separator(idx) : separator
    return idx === array.length - 1 ? [...acc, cur] : [...acc, cur, s]
  }, [] as T[])
}

export function sum(numbers: number[]) {
  return numbers.reduce((acc, cur) => {
    return acc + cur
  }, 0)
}

export function dups<T>(array: T[]): Set<T> {
  const itemsSeenSet = new Set<T>()
  const dupsSet = new Set<T>()
  for (const item of array) {
    if (itemsSeenSet.has(item)) {
      dupsSet.add(item)
    }

    itemsSeenSet.add(item)
  }

  return dupsSet
}

export function* rangeGenerator(from: number, to: number, step = 1) {
  // eslint-disable-next-line immutable/no-let
  for (let i = from; i <= to; from < to ? (i += step) : (i -= step)) {
    yield i
  }
}

export function range(from: number, to: number, step = 1) {
  return Array.from(rangeGenerator(from, to, step))
}

export function keys<K extends string, T>(obj: { [key in K]?: T }) {
  return Array.from(Object.keys(obj)) as K[]
}

export function values<T>(obj: { [key: string]: T }) {
  return Array.from(Object.values(obj)) as Exclude<T, undefined>[]
}

export function entries<T, O extends { [key: string]: T }>(obj: O) {
  return Array.from(Object.entries(obj)) as [
    keyof O extends string ? keyof O : string,
    Exclude<O[keyof O], undefined>,
  ][]
}

export function arrayToChunks<T>(input: T[], chunkSize: number) {
  const numberOfChunks = Math.ceil(input.length / chunkSize)
  return new Array(numberOfChunks).fill(null).map((_, index) => {
    const start = index * chunkSize
    return input.slice(start, start + chunkSize)
  })
}

export function rotate<T>(arr: ReadonlyArray<T>, count = 1): T[] {
  return [...arr.slice(count, arr.length), ...arr.slice(0, count)]
}
