export function delay(ms: number) {
  return new Promise((resolve) => {
    return setTimeout(resolve, ms)
  })
}

export function then<A, B>(fn: (a: A) => B | Promise<B>): (promise: Promise<A>) => Promise<B> {
  return (a) => {
    return a.then(fn)
  }
}
